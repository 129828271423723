import axios, { AxiosResponse } from 'axios'
import { CreateSaleOrder, CreateSaleOrderRequestModel, Packages, SaleOrderDetailDto, SaleOrderEditResponse, SaleOrderForInvoice, SaleOrderItem, SaleOrderPayment, SalesOrder, SalesOrderPackedQueryResponse, SalesOrderQueryResponse, SalesOrderUpdateStatus, StatusHistory, UpdateSaleOrderRequestModel, } from './_models'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"
import { FileResult, Result } from '../../../../core/models/Result'
import { Product } from '../../../catalog/product/product-list/core/_models'
const API_URL = process.env.REACT_APP_STORE_API_URL
const SalesOrder_BYID_URL = `${API_URL}/saleorders`
const SALESORDER_EDIT_ID_URL = `${API_URL}/saleorders/edit`
const SalesOrder_ADD_URL = `${API_URL}/saleorders`
const SalesOrder_LIST_URL = `${API_URL}/saleorders/search`
const SalesOrder_TAXEDLIST_URL = `${API_URL}/saleorders/search-with-tax`
const Sales_Order_Payment_URL = `${API_URL}/saleorders/saleorderpayment`
const SalesOrder_STATUS_URL = `${API_URL}/saleorders/update-status`
const SalesPackages_URL = `${API_URL}/packages`
const SaleOrderItem_URL = `${API_URL}/saleorders/saleorderitem`
const GENERATE_PDF_URL = `${API_URL}/saleorders/generatepdf`
const SLAESORDER_PACKED_URL = `${API_URL}/saleorders/unpacked-saleorders`
const SALEORDER_STATUS_HISTORY_URL = `${API_URL}/saleorders/statushistory`
const ProductColor_URL = `${API_URL}/products/colordetails`
const SALEORDER_WITH_TAX_URL = `${API_URL}/saleorders/create-with-tax`
const SalesOrder_TAX_BYID_URL = `${API_URL}/saleorders/update-with-tax`
const SALEORDER_INVOICE_LIST_BY_ID_URL = `${API_URL}/saleorders/sale-order-for-invoice`

const getSalesOrderList = (filter: PaginationFilter): Promise<SalesOrderQueryResponse> => {

  return axios
    .post(`${SalesOrder_LIST_URL}`, filter)
    .then((d: AxiosResponse<SalesOrderQueryResponse>) => {

      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getTaxedSalesOrderList = (filter: PaginationFilter): Promise<SalesOrderQueryResponse> => {

  return axios
    .post(`${SalesOrder_TAXEDLIST_URL}`, filter)
    .then((d: AxiosResponse<SalesOrderQueryResponse>) => {

      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getSalesOrderById = async (id: ID): Promise<SaleOrderDetailDto> => {
  return await axios
    .get(`${SalesOrder_BYID_URL}/${id}`)
    .then((response: AxiosResponse<SaleOrderDetailDto>) => response.data)
    .then((response: SaleOrderDetailDto) => response)
}

const getSalesOrderForEditById = async (id: ID): Promise<SaleOrderEditResponse> => {
  return await axios
    .get(`${SALESORDER_EDIT_ID_URL}/${id}`)
    .then((response: AxiosResponse<SaleOrderEditResponse>) => response.data)
    .then((response: SaleOrderEditResponse) => response)
}


const createSalesOrder = async (salesOrder: CreateSaleOrderRequestModel): Promise<Result> => {
  return await axios
    .post(SalesOrder_ADD_URL, salesOrder)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateSalesOrder = (salesOrder: UpdateSaleOrderRequestModel): Promise<Result> => {
  return axios
    .put(`${SalesOrder_BYID_URL}/${salesOrder.id}`, salesOrder)
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}



const getSOPaymentById = async (id: ID): Promise<Result | SaleOrderPayment> => {
  return await axios
    .get(`${Sales_Order_Payment_URL}/${id}`)
    .then((response: AxiosResponse<SaleOrderPayment>) => response.data)
    .then((response: SaleOrderPayment) => response)
    .catch((err: Result) => {
      return err
    })
}
const updateSalesOrderStatus = (soStatus: SalesOrderUpdateStatus): Promise<Result> => {
  return axios
    .post(`${SalesOrder_STATUS_URL}`, soStatus)
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
const getSaleOrderItem = async (id: string): Promise<Result | SaleOrderItem[]> => {
  return await axios
    .get(`${SaleOrderItem_URL}/${id}`)
    .then((response: AxiosResponse<SaleOrderItem[]>) => response.data)
    .then((response: SaleOrderItem[]) => response)
    .catch((err: Result) => {
      return err
    })
}
const createSalesPackage = async (
  sopackage: Packages
): Promise<Result> => {
  return await axios
    .post(SalesPackages_URL, {
      saleOrderId: sopackage.saleOrderId,
      packageNumber: sopackage.packageNumber,
      packageDate: sopackage.packageDate,
      notes: sopackage.notes,
      packageItems: sopackage.packageItems
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err;
    });
};


const saleOrderGeneratePdf = async (id: ID): Promise<Result | FileResult> => {
  return await axios
    .get(`${GENERATE_PDF_URL}/${id}`, {
      responseType: 'blob'
    })
    .then((response: AxiosResponse<any>) => {
      let filename = response.headers['content-disposition']
        .split(';')
        .find((n: any) => n.includes('filename='))
        .replace('filename=', '')
        .trim();

      var result: FileResult = {
        data: response.data,
        name: filename
      }

      return result
    })
    .catch((err: Result) => {
      return err
    })
}

const getSaleOrderPackedList = (filter: PaginationFilter): Promise<SalesOrderPackedQueryResponse> => {
  return axios
    .post(`${SLAESORDER_PACKED_URL}`, filter)
    .then((d: AxiosResponse<SalesOrderPackedQueryResponse>) => {

      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getSaleOrderStatusHistory = async (id: number): Promise<Result | StatusHistory[]> => {
  return axios
    .get(`${SALEORDER_STATUS_HISTORY_URL}/${id}`)
    .then((response: AxiosResponse<StatusHistory[]>) => response.data)
    .then((response: StatusHistory[]) => response)
    .catch((err: Result) => {
      return err
    })
}


const getProductColor = async (productGroupId?: number, polishingTypeId?: number): Promise<Result | Product> => {
  let url: string = `${ProductColor_URL}`;

  if (polishingTypeId !== undefined) {
    url =
      url +
      `?productGroupId=${productGroupId}` +
      `&polishingTypeId=${polishingTypeId}`;
  }
  return axios
    .get(`${url}`)
    .then((response: AxiosResponse<Product>) => response.data)
    .then((response: Product) => response)
    .catch((err: Result) => {
      return err
    })
}

const createSalesOrderWithTax = async (salesOrder: CreateSaleOrderRequestModel): Promise<Result> => {
  return await axios
    .post(SALEORDER_WITH_TAX_URL, salesOrder)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateSalesOrderWithTax = (salesOrder: UpdateSaleOrderRequestModel): Promise<Result> => {
  return axios
    .put(`${SalesOrder_TAX_BYID_URL}/${salesOrder.id}`, salesOrder)
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getSalesOrderInvoiceById = async (id: ID): Promise<Result | SaleOrderForInvoice> => {
  return await axios
    .get(`${SALEORDER_INVOICE_LIST_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<SaleOrderForInvoice>) => response.data)
    .then((response: SaleOrderForInvoice) => response)
    .catch((err: Result) => {
      return err
    })
}
export {
  getSalesOrderById,
  getSalesOrderList,
  getTaxedSalesOrderList,
  createSalesOrder,
  updateSalesOrder,
  getSOPaymentById,
  updateSalesOrderStatus,
  createSalesPackage,
  getSaleOrderItem,
  saleOrderGeneratePdf,
  getSaleOrderPackedList,
  getSaleOrderStatusHistory,
  getProductColor,
  createSalesOrderWithTax,
  updateSalesOrderWithTax,
  getSalesOrderInvoiceById, 
  getSalesOrderForEditById
}
