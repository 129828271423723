import React, {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {isNotEmpty, KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getBillDetails} from '../_requests'
import {formatCurrency, formatDate, formatPercentage} from '../../../../core/utils/helpers'
import {ReceivedBillItems} from '../_models'
import {Loading} from '../../../../core/shared/components/Loading'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {ColumnGroup} from 'primereact/columngroup'
import {Row} from 'react-bootstrap'

const BillDetails = () => {
  const {id} = useParams()
  const {isLoading, data: result} = useQuery(
    ['getBillData'],
    async () => {
      return await getBillDetails(id)
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  const [activeTab, setActiveTab] = useState<string>('bill_summary')

  const initialValues = {
    id: result?.id,
    vendorId: result?.vendorId,
    vendorName: result?.vendorName,
    vendorEmail: result?.vendorEmail,
    vendorMobileNumber: result?.vendorMobileNumber,
    purchaseOrderId: result?.purchaseOrderId,
    purchaseOrderNumber: result?.purchaseOrderNumber,
    billNumber: result?.billNumber,
    billDate: result?.billDate,
    orderTotal: result?.orderTotal,
    orderSubTotal: result?.orderSubTotal,
    paymentStatusId: result?.paymentStatusId,
    paymentStatusName: result?.paymentStatusName,
    billStatusId: result?.billStatusId,
    billStatusName: result?.billStatusName,
    vendorAddressLine1: result?.billAddressLine1,
    vendorAddressLine2: result?.billAddressLine2,
    vendorCityName: result?.billCityName,
    vendorStateId: result?.billStateId,
    vendorStateName: result?.billStateName,
    vendorCountryId: result?.billCountryId,
    vendorCountryName: result?.billCountryName,
    vendorZipCode: result?.billZipCode,
    deliveryAddressLine1: result?.deliveryAddressLine1,
    deliveryAddressLine2: result?.deliveryAddressLine2,
    deliveryCityName: result?.deliveryCityName,
    deliveryStateName: result?.deliveryStateName,
    deliveryCountryName: result?.deliveryCountryName,
    deliveryZipCode: result?.deliveryZipCode,
    dueDate: result?.dueDate,
    itemList: result?.itemList,
    paymentList: result?.paymentList,
    otherCharges: result?.otherCharges,
    totalTaxAmount: result?.totalTaxAmount,
    roundOff: result?.roundOff,
  }

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer='SubTotal:' footerStyle={{textAlign: 'right'}} />
        <Column footer={initialValues?.itemList?.reduce((acc, item) => acc + item?.quantity, 0)} />
        <Column
          footer={formatCurrency(
            initialValues?.itemList?.reduce((acc, item) => acc + item?.productPrice, 0)
          )}
        />
        {initialValues.totalTaxAmount !== 0 && (
          <Column footer={formatCurrency(initialValues?.totalTaxAmount)} />
        )}
        <Column footer={formatCurrency(initialValues?.orderSubTotal)} />
      </Row>
      {initialValues?.otherCharges?.map((ocitem) => (
        <Row key={ocitem.rowNumber}>
          <Column
            colSpan={initialValues.totalTaxAmount !== 0 ? 4 : 3}
            footer={ocitem.label}
            footerStyle={{textAlign: 'right'}}
          />
          <Column footer={formatCurrency(ocitem.amount)} />
        </Row>
      ))}
      <Row>
        <Column
          colSpan={initialValues.totalTaxAmount !== 0 ? 4 : 3}
          footer='Round Off'
          footerStyle={{textAlign: 'right'}}
        />
        <Column footer={formatCurrency(initialValues.roundOff)} />
      </Row>
      <Row>
        <Column
          colSpan={initialValues.totalTaxAmount !== 0 ? 4 : 3}
          footer='Grand Total'
          footerStyle={{textAlign: 'right'}}
        />
        <Column footer={formatCurrency(initialValues.orderTotal)} />
      </Row>
    </ColumnGroup>
  )

  return (
    <div id='kt_app_content' className='app-content  flex-column-fluid '>
      <div id='kt_app_content_container' className='app-container  container-xxl '>
        <div className='d-flex flex-column'>
          <KTCard>
            <KTCardBody>
              <div className='d-flex flex-wrap flex-stack gap-5 gap-lg-10'>
                <ul
                  className='nav profile-nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link text-active-primary active cursor-pointer'
                      data-bs-toggle='tab'
                      onClick={() => setActiveTab('bill_summary')}
                      aria-selected='true'
                      role='tab'
                    >
                      Bill Summary
                    </div>
                  </li>

                  <li className='nav-item' role='presentation'>
                    <div
                      className='nav-link text-active-primary cursor-pointer'
                      data-bs-toggle='tab'
                      onClick={() => setActiveTab('payment_details')}
                      aria-selected='false'
                      role='tab'
                      tabIndex={-1}
                    >
                      Payment Details
                    </div>
                  </li>
                </ul>
              </div>
            </KTCardBody>
          </KTCard>

          <div className='d-flex flex-column flex-xl-row gap-5 mt-5'>
            <div className='card flex-row-fluid w-xl-50'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Bill Details (#{initialValues.billNumber})</h3>
                </div>
              </div>

              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-calendar fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>{' '}
                            Bill Date
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {formatDate(initialValues.billDate, 'dd MMM yyyy')}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-calendar fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>{' '}
                            Due Date
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {initialValues.dueDate &&
                            formatDate(initialValues.dueDate, 'dd MMM yyyy')}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-wallet fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                            </i>{' '}
                            Bill Status
                          </div>
                        </td>
                        <td className='fw-bold text-end'>{initialValues.billStatusName}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-wallet fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                            </i>{' '}
                            Payment Status
                          </div>
                        </td>
                        <td className='fw-bold text-end'>{initialValues.paymentStatusName}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='card flex-row-fluid w-xl-50'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Vendor Details</h3>
                </div>
              </div>

              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </i>{' '}
                            Vendor
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <label className='text-gray-600 text-hover-primary'>
                              {initialValues.vendorName || 'Name not available'}
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </i>{' '}
                            Vendor Email
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <label className='text-gray-600 text-hover-primary'>
                              {initialValues.vendorEmail || 'Email is not available'}
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </i>{' '}
                            Vendor Phone
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <label className='text-gray-600 text-hover-primary'>
                              {initialValues.vendorMobileNumber || 'Phone number is not available'}
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className='tab-content'>
            <div
              className={`tab-pane fade ${activeTab == 'bill_summary' ? 'active show' : ''} `}
              id='kt_ecommerce_purchase_bill_summary'
              role='tab-panel'
            >
              <div className='d-flex flex-column gap-5 gap-lg-5 mt-5'>
                <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5'>
                  <div className='card position-relative w-xl-50'>
                    <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                      <i className='ki-solid ki-two-credit-cart' style={{fontSize: '14em'}}></i>
                    </div>

                    <div className='card-header'>
                      <div className='card-title'>
                        <h3>Vendor Address</h3>
                      </div>
                    </div>

                    {initialValues.vendorAddressLine1 ||
                    initialValues.vendorAddressLine2 ||
                    initialValues.vendorStateName ||
                    initialValues.vendorCityName ? (
                      <div className='card-body'>
                        {initialValues.vendorAddressLine1}, {initialValues.vendorAddressLine2}
                        <br />
                        {initialValues.vendorStateName},
                        <br />
                        {initialValues.vendorCityName}.
                        <br />
                        {initialValues.vendorZipCode}
                        <br />
                        {initialValues.vendorCountryName}
                      </div>
                    ) : (
                      <div className='card-body'>
                        <h4>No Vendor Address Available</h4>
                      </div>
                    )}
                  </div>

                  <div className='card position-relative w-xl-50'>
                    <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                      <i className='ki-solid ki-delivery' style={{fontSize: '13em'}}></i>
                    </div>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h3>Delivery Address</h3>
                      </div>
                    </div>
                    {initialValues.deliveryAddressLine1 ||
                    initialValues.deliveryAddressLine2 ||
                    initialValues.deliveryStateName ||
                    initialValues.deliveryCityName ? (
                      <div className='card-body'>
                        {initialValues.deliveryAddressLine1}
                        <br />
                        {initialValues.deliveryAddressLine2}
                        <br />
                        {initialValues.deliveryStateName},
                        <br />
                        {initialValues.deliveryCityName}.
                        <br />
                        {initialValues.deliveryZipCode}
                        <br />
                        {initialValues.deliveryCountryName}
                      </div>
                    ) : (
                      <div className='card-body'>
                        <h4>No Delivery Address Available</h4>
                      </div>
                    )}
                  </div>
                </div>

                <div className='card flex-row-fluid overflow-hidden'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h3>Bill Items</h3>
                    </div>
                  </div>
                  <div className='card-body '>
                    <div className='table-responsive '>
                      {/* <table className='table border table-striped table-row-bordered gy-5 gs-7'>
                        <thead>
                          <tr className='fw-semibold fs-6 text-gray-800'>
                            <th className='min-w-175px'>Product</th>
                            <th className='min-w-175px text-end'>Product Price</th>
                            <th className='min-w-70px text-end'>Quantity</th>
                            {initialValues?.totalTaxAmount !== 0 && (
                              <th className='min-w-70px text-end'>Tax</th>
                            )}
                            <th className='min-w-165px text-end'>Total Price</th>
                          </tr>
                        </thead>
                        <tbody className='fw-semibold text-gray-600'>
                          {initialValues?.itemList &&
                            initialValues?.itemList?.map((item: ReceivedBillItems) => {
                              return (
                                <>
                                  <tr key={item?.billItemId} className='hover:bg-gray-100'>
                                    <td>
                                      <div className='d-flex align-items-center'>
                                        <div className='ms-5'>{item.productName}</div>
                                      </div>
                                    </td>
                                    <td className='text-end'>
                                      {formatCurrency(item.productPrice)}{' '}
                                    </td>
                                    <td className='text-end'>{item.quantity} </td>
                                    {initialValues?.totalTaxAmount !== 0 && (
                                      <td className='text-end'>
                                        {item.billItemTaxDtos[0]?.taxPercent &&
                                          formatPercentage(
                                            item.billItemTaxDtos[0]?.taxPercent
                                          )}{' '}
                                      </td>
                                    )}
                                    <td className='text-end'>{formatCurrency(item?.subTotal)} </td>
                                  </tr>
                                </>
                              )
                            })}
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='ms-5'>Sub Total</div>
                              </div>
                            </td>
                            <td className='text-end'>
                              {formatCurrency(
                                initialValues?.itemList?.reduce(
                                  (sum, item) => sum + (item.productPrice || 0),
                                  0
                                )
                              )}
                            </td>
                            <td className='text-end'>
                              {' '}
                              {initialValues?.itemList?.reduce(
                                (sum, item) => sum + (item.quantity || 0),
                                0
                              )}
                            </td>
                            {initialValues?.totalTaxAmount !== 0 && <td className='text-end'></td>}
                            <td className='text-end'>
                              {formatCurrency(
                                initialValues?.itemList?.reduce(
                                  (sum, item) => sum + (item.subTotal || 0),
                                  0
                                )
                              )}{' '}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot className='fw-semibold text-gray-600 mt-5'>
                          {initialValues.otherCharges?.map((oc) => (
                            <tr>
                              <td className='text-end'></td>
                              <td className='text-end'></td>
                              {initialValues?.totalTaxAmount !== 0 && (
                                <td className='text-end'></td>
                              )}{' '}
                              <td className='fs-3 text-gray-900 text-end'>{oc.label}</td>
                              <td className='text-gray-900 fs-3 fw-bolder text-end'>
                                {formatCurrency(oc.amount)}
                              </td>
                            </tr>
                          ))}
                          {initialValues?.roundOff !== 0 && (
                            <tr>
                              <td className='text-end'></td>
                              <td className='text-end'></td>
                              <td className='text-end'></td>
                              <td className='fs-3 text-gray-900 text-end'>Round Off </td>
                              <td className='text-gray-900 fs-3 fw-bolder text-end'>
                                {formatCurrency(initialValues?.roundOff)}
                              </td>
                            </tr>
                          )}
                          {initialValues?.totalTaxAmount !== 0 && (
                            <tr>
                              <td className='text-end'></td>
                              <td className='text-end'></td>
                              <td className='text-end'></td>
                              <td className='fs-3 text-gray-900 text-end'>Total Tax </td>
                              <td className='text-gray-900 fs-3 fw-bolder text-end'>
                                {formatCurrency(initialValues?.totalTaxAmount)}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td className='text-end'></td>
                            <td className='text-end'></td>
                            {initialValues?.totalTaxAmount !== 0 && <td className='text-end'></td>}
                            <td className='fs-3 text-gray-900 text-end'>Grand Total </td>
                            <td className='text-gray-900 fs-3 fw-bolder text-end'>
                              {formatCurrency(initialValues?.orderTotal)}
                            </td>
                          </tr>
                        </tfoot>
                      </table>*/}
                    </div>
                    <DataTable
                      stripedRows
                      tableClassName='table table-bordered table-hover mb-0'
                      value={initialValues?.itemList}
                      tableStyle={{minWidth: '60rem'}}
                      footerColumnGroup={footerGroup}
                      emptyMessage='No Bills Payments found.'
                    >
                      <Column field='productName' header='Product'></Column>
                      <Column field='quantity' header='Quantity'></Column>
                      <Column
                        field='productPrice'
                        header='Product Price'
                        body={(rowData) => formatCurrency(rowData?.productPrice)}
                      ></Column>
                      {initialValues.totalTaxAmount !== 0 && (
                        <Column
                          field='totalTaxAmount'
                          header='Tax Amount'
                          body={(rowData) => (
                            <>
                              {formatCurrency(rowData?.billItemTaxDtos[0].taxAmount)} ({' '}
                              {rowData?.billItemTaxDtos[0].taxPercent}%)
                            </>
                          )}
                        ></Column>
                      )}
                      <Column
                        field='subTotal'
                        header='Total Price'
                        body={(rowData) => formatCurrency(rowData.subTotal)}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='tab-content'>
            <div
              className={`tab-pane fade ${activeTab == 'payment_details' ? 'active show' : ''} `}
              id='kt_ecommerce_purchase_payment_details'
              role='tab-panel'
            >
              {result?.paymentList?.length == 0 ? null : (
                <KTCard className='mt-5'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h3>Payment History</h3>
                    </div>
                  </div>
                  <KTCardBody className='flex-row-fluid overflow-hidden mt-5'>
                    <div className='table-responsive '>
                      <table className='table border table-striped table-row-bordered gy-5 gs-7'>
                        <thead>
                          <tr className='fw-semibold fs-6 text-gray-800'>
                            <th className='w-25 text-center'>Payment Method</th>
                            <th className='w-25 text-center'>Reference Number</th>
                            <th className='w-25 text-center'>Payment Date</th>
                            <th className='w-25 text-center'>Amount Received</th>
                          </tr>
                        </thead>
                        <tbody className='fw-semibold text-gray-600'>
                          {result?.paymentList?.map((plData) => (
                            <tr>
                              <td className='text-center'>{plData.paymentModeName}</td>
                              <td className='text-center'>{plData.referenceNumber}</td>
                              <td className='text-center'>
                                {formatDate(plData?.paymentDate, 'dd MMM yyyy')}
                              </td>
                              <td className='text-center'>{formatCurrency(plData.amountPaid)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </KTCardBody>
                </KTCard>
              )}
            </div>
          </div>
        </div>
        <div className='card mt-5'>
          <div className='card-body text-end'>
            <Link to={`/purchase/bills/list`} className='btn btn-primary'>
              <i className='ki-duotone ki-left fs-2' /> Back
            </Link>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  )
}

export default BillDetails
