import { Routes, Route, Outlet } from "react-router-dom"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { PaymentDetailWrapper } from "./pages/PaymentDetailWrapper"
import PaymentList from "./pages/PaymentList"
import AddPayment from "./pages/AddPayment"


const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const PaymentPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Payment</PageTitle>
              <PaymentList />
            </>
          }
        />

        <Route
          path='detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Detail Payment</PageTitle>
              <PaymentDetailWrapper />
            </>
          }
        />

        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Payment</PageTitle>
              <AddPayment />
            </>
          }
        />
        <Route
          path='add/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Payment</PageTitle>
              <AddPayment />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default PaymentPage
