import Select from 'react-select';
import { SelectOptionProps } from '../../models/SelectOptionProps';
import { useEffect, useState } from 'react';
import { ColorDetails } from '../../../modules/sales/salesOrder/core/_models';
import { PaginationFilter } from '../../../../_metronic/helpers';
import { Category } from '../../../modules/catalog/category/category-list/core/_models';
import { getCategoryList } from '../../../modules/catalog/category/category-list/core/_requests';
import { ProductGroup } from '../../../modules/catalog/productGroup/productgroup-list/core/_models';
import { getProductGroupList } from '../../../modules/catalog/productGroup/productgroup-list/core/_requests';
import { getProductColor } from '../../../modules/sales/salesOrder/core/_requests';
import { PolishingType } from '../core/_model';
import { getPolishingTypeList } from '../core/_request';
import { Product } from '../../../modules/catalog/product/product-list/core/_models';
import { getProductById } from '../../../modules/catalog/product/product-list/core/_requests';

const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,
    advancedFilter: {
      field: 'isActive',
      operator: 'eq',
      value: true
    }
  };

  interface ProductSelectionProps {
    onSelect: (newValue: Product, quantity: number) => void;
}

const ProductSelection = ({ onSelect }: ProductSelectionProps) => {
    const [categoryList, setCategoryList] = useState<SelectOptionProps[]>([]);
    const [productGroupList, setProductGroupList] = useState<SelectOptionProps[]>([]);
    const [selectedProductGroup, setSelectedProductGroup] = useState<number | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [polishId, setPolishId] = useState<number | null>(null)
    const [colorList, setColorList] = useState<ColorDetails[]>([]);
    const [polishingTypeList, setPolishingTypeList] = useState<SelectOptionProps[]>([]);
    const [quantities, setQuantities] = useState<{ [colorId: number]: number }>({}); //state to store color quantity
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        let result: any
        const updatedPaginationFilter: PaginationFilter = {
            ...paginationFilter,
            advancedFilter: {
                field: 'isActive',
                operator: 'eq',
                value: true,
            },

        };
        getCategoryList(updatedPaginationFilter).then((v) => {
            result = v.data as Category[];
            let categoryArray: any[] = [];
            result.map((item) => {
                categoryArray.push({ value: item.id, label: item.parentChildCategoryName });
            });
            setCategoryList(categoryArray);
        });


        getPolishingTypeList(paginationFilter).then((v) => {
            result = v.data as PolishingType[]
            let polishingTypeArray: any[] = []
            result.filter(x => x.name != 'NA').map((item: any) => {
                return polishingTypeArray.push({ value: item.id, label: item.name })
            })
            setPolishingTypeList(polishingTypeArray)
        })


    }, [selectedCategory]);

    const onCategoryChange = (e) => {

        const updatedPaginationFilter: PaginationFilter = {
            ...paginationFilter,
            advancedFilter: {
                filters: [
                    { field: 'isActive', operator: 'eq', value: true },

                    {
                        field: "categoryId",
                        operator: "eq",
                        value: e.value,
                    }
                ],
                logic: "and"
            }
        };

        getProductGroupList(updatedPaginationFilter)
            .then((response) => {
                const result = response.data as ProductGroup[];
                let pgArray: any[] = []
                result.map((item: any) => {
                    return pgArray.push({ value: item.id, label: item.name })
                })
                
                setProductGroupList(pgArray);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    const handleSearch = () => {
        getProductColor(selectedProductGroup as number, polishId as number)
            .then((response) => {
                const result = response as ColorDetails[]
                setColorList(result)
                if (result.length > 0) {
                    setError(false)
                }
                else {
                    setError(true)
                }

            })



    };

    const handlePolishingChange = (e) => {
        setPolishId(e.value)
    }

    const handleQuantityChange = (colorId: number, quantity: number) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [colorId]: quantity
        }));
    };

    const handleAddClick = () => {
        colorList.forEach(async color => {
            const quantity = quantities[color.colorId];

            if (quantity != undefined && quantity != null && quantity > 0) {
                try {
                    const product = await getProductById(color.productId);
                    onSelect(product as Product, quantity);
                } catch (err) {
                    console.error(`Error fetching product by ID ${color.productId}:`, err);
                }
            }
        });

        setSelectedProductGroup(null);
        setSelectedCategory(null);
        setPolishId(null);
        setPolishingTypeList([]);
        setColorList([]);
        setQuantities({});
    };

    const clearFilters = () => {

        setSelectedProductGroup(null);
        setSelectedCategory(null);
        setPolishId(null)
        setPolishingTypeList([])
        setColorList([])
    }


    return (
        <>
            <div className="card-body">
                <div className="row align-items-end">
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                        <label className="form-label">Category</label>
                        <Select
                            name="categoryList"
                            className="react-select-container"
                            options={categoryList}
                            placeholder="Select a Category"
                            isMulti={false}
                            onChange={(selectedOption) => {
                                onCategoryChange(selectedOption)
                                setSelectedCategory(selectedOption?.value as number);
                            }}
                            value={categoryList.find(option => option.value === selectedCategory) || null}
                        />
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                        <label className="form-label">Product group</label>
                        <Select
                            name="categoryList"
                            className="react-select-container"
                            options={productGroupList}
                            placeholder="Select a ProductGroup"
                            isMulti={false}
                            onChange={(selectedOption) => {
                                setSelectedProductGroup(selectedOption?.value as number);
                            }}
                            value={productGroupList.find(option => option.value === selectedProductGroup) || null}

                        />
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                        <label className="form-label">Polishing Type</label>
                        <Select
                            className=
                            'form-select-solid'
                            value={polishingTypeList.find(option => option.value === polishId) || null}
                            name="polishIds"
                            options={polishingTypeList}
                            onChange={(selectedPT) => handlePolishingChange(selectedPT)}
                            placeholder="Select PolishingType"
                        ></Select>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-6 mb-5 fv-row fv-plugins-icon-container">
                        <button type='button' className="btn btn-primary mt-6" onClick={handleSearch}>
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body pt-0">

                {colorList.length > 0 ?
                    <><div className='opt1 relative-options show-opt'>
                        <table className="color-tbl">
                            <tbody>
                                <tr className='row'>
                                    {colorList.map((color, index) => (
                                        <td className='col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3'  key={index}>
                                            <div className="form-group">
                                                <label className="clr-label">{color.colorName}
                                                    <input type="number" min={1} className="color-opt form-color" value={quantities[color.colorId] || ""}
                                                        onChange={(e) => {
                                                            let qty = parseInt(e.target.value)
                                                            if (qty < 0) {
                                                                e.target.value = ''
                                                            }
                                                            else if (qty > 99999) {
                                                                e.target.value = ''
                                                            }
                                                            else {
                                                                handleQuantityChange(color.colorId, parseInt(e.target.value))
                                                            }
                                                        }

                                                        }
                                                    /> </label>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                        <div className='text-right'>

                            <button type='button' className="btn btn-primary me-5" onClick={
                                handleAddClick
                            }
                            >
                                Add
                            </button>
                            <button type='button' className="btn btn-primary" onClick={clearFilters}>
                                Clear
                            </button>
                        </div>

                    </>
                    : error && <span className=' '><h3 className='text-danger mt-3 text-center'>No Record Found</h3></span>}
            </div>
        </>
    )
}

export default ProductSelection