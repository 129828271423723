import React, { useState } from 'react'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { CreateBankAccountPayload } from '../core/_models'
import { useQuery } from 'react-query'
import { getBankAccountById } from '../core/_requests'
import { useParams, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const BankAccountDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<string>('bank_transaction')
  const { data: bankAccountData, isLoading: isBankAccountLoading } = useQuery({
    queryKey: ['bank-account', id],
    queryFn: () => getBankAccountById(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
  })

  const bankAccount = isBankAccountLoading ? null : (bankAccountData as CreateBankAccountPayload)
  return (
    <div id='kt_app_content' className='app-content  flex-column-fluid '>
      <div id='kt_app_content_container' className='app-container  container-xxl '>
        <div className='d-flex flex-column gap-5 gap-lg-5'>
          <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5'>
            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Bank Details</h3>
                </div>
              </div>
              <div className='card-body'>

                <div className="row">
                  <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                    <div className="fv-row fv-plugins-icon-container">
                      <label className="fs-5 fw-bold mb-2">Account Type :<span className="fs-6 fw-semibold"> {bankAccount?.accountTypeName}</span></label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                    <div className="fv-row">
                      <label className="fs-5 fw-bold mb-2">Bank name :<span className="fs-6 fw-semibold"> {bankAccount?.name}</span></label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                    <div className="fv-row fv-plugins-icon-container">
                      <label className="fs-5 fw-bold mb-2">Account Holder Name :<span className="fs-6 fw-semibold"> {bankAccount?.accountHolderName}</span></label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                    <div className="fv-row">
                      <label className="fs-5 fw-bold mb-2">Branch Name Address :<span className="fs-6 fw-semibold"> {bankAccount?.branchNameAddress}</span></label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                    <div className="fv-row fv-plugins-icon-container">
                      <label className="fs-5 fw-bold mb-2">Account Number :<span className="fs-6 fw-semibold"> {bankAccount?.accountNumber}</span></label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                    <div className="fv-row">
                      <label className="fs-5 fw-bold mb-2">IFSC Code :<span className="fs-6 fw-semibold"> {bankAccount?.ifscCode}</span></label>
                    </div>
                  </div>

                  </div>

                  <div className="row">
                    <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                      <div className="fv-row fv-plugins-icon-container">
                        <label className="fs-5 fw-bold mb-2">UPI ID :<span className="fs-6 fw-semibold"> {bankAccount?.upiId}</span></label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                      <div className="fv-row">
                        <label className="fs-5 fw-bold mb-2">Swift/BIC Code :<span className="fs-6 fw-semibold"> {bankAccount?.switfbicCode}</span></label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                      <div className="fv-row fv-plugins-icon-container">
                        <label className="fs-5 fw-bold mb-2">Is Default :<span className="fs-6 fw-semibold"> {bankAccount?.isDefault ? 'Yes' : 'No'}</span></label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-12 col-xl-12 col-xxl-6">
                      <div className="fv-row">
                        <label className="fs-5 fw-bold mb-2">Swift/BIC Code :<span className="fs-6 fw-semibold"> {bankAccount?.switfbicCode}</span></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <KTCard>
              <KTCardBody>
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      navigate('/settings/bank-account/list')
                    }}
                  >
                    <i className='ki-duotone ki-left fs-2' /> Back
                  </button>
                </div>
              </KTCardBody>
            </KTCard>
          </div>
        </div>
      </div>
  )
}

export default BankAccountDetails
