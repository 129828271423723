import React, { FC, useEffect, useState } from 'react';
import { Result } from '../../../../core/models/Result';
import { EditRoleModel, Role } from '../core/_models';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { SWSection, SWResource, SWAction, SWPermissionNameFor } from '../../../../core/shared/core/permissionUtils';
import { updateRolePermissions } from '../core/_requests';

type Props = {
  isRoleLoading?: boolean;
  data: Role | Result;
};

const PermissionsPage: FC<Props> = ({ isRoleLoading, data }) => {
  const permission = data as Role;
  console.log('permi', permission.permissions);

  const initialSelectedPermissions = permission.permissions || [];
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(initialSelectedPermissions);
  const navigate = useNavigate();

  const permissionDependencies: Record<string, string[]> = {
    'Permissions.Store.Packages.Search': [
      'Permissions.Store.SaleOrders.Search',
    ],
    'Permissions.Store.Packages.View': [
      'Permissions.Store.SaleOrders.Search',
    ],
    'Permissions.Store.Packages.Update': [
      'Permissions.Store.SaleOrders.Search',
    ],
     'Permissions.Store.Packages.Create': [
      'Permissions.Store.SaleOrders.Search',
    ],
    'Permissions.Store.Packages.Delete': [
      'Permissions.Store.SaleOrders.Search',
    ],
    'Permissions.Store.Packages.Export': [
      'Permissions.Store.SaleOrders.Search',
    ],

    'Permissions.Store.Shipments.Search': [
      'Permissions.Store.SaleOrders.Search',
    ],
    'Permissions.Store.Shipments.View': [
      'Permissions.Store.SaleOrders.Search',
    ],
    'Permissions.Store.Shipments.Update': [
      'Permissions.Store.SaleOrders.Search',
    ],
    'Permissions.Store.Shipments.Create': [
      'Permissions.Store.SaleOrders.Search',
    ],
    'Permissions.Store.Shipments.Delete': [
      'Permissions.Store.SaleOrders.Search',
    ],
    'Permissions.Store.Shipments.Export': [
      'Permissions.Store.SaleOrders.Search',
    ],

    'Permissions.Store.PurchaseReceives.Search':[
      'Permissions.Store.PurchaseOrders.Search'
    ],
    'Permissions.Store.PurchaseReceives.Update':[
      'Permissions.Store.PurchaseOrders.Search'
    ],
    'Permissions.Store.PurchaseReceives.View':[
      'Permissions.Store.PurchaseOrders.Search'
    ],
    'Permissions.Store.PurchaseReceives.Create':[
      'Permissions.Store.PurchaseOrders.Search'
    ],
    'Permissions.Store.PurchaseReceives.Delete':[
      'Permissions.Store.PurchaseOrders.Search'
    ],
    'Permissions.Store.PurchaseReceives.Export':[
      'Permissions.Store.PurchaseOrders.Search'
    ]
  };

  const updateDependencies = (updatedPermissions: string[]) => {
    const newPermissions = [...updatedPermissions];
    Object.keys(permissionDependencies).forEach((permission) => {
      if (updatedPermissions.includes(permission)) {

        permissionDependencies[permission].forEach(dep => {
          if (!newPermissions.includes(dep)) {
            newPermissions.push(dep);
          }
        });
      } else {

        Object.values(permissionDependencies).forEach(deps => {
          deps.forEach(dep => {
            if (newPermissions.includes(dep) && !Object.keys(permissionDependencies).some(key => newPermissions.includes(key))) {
              newPermissions.splice(newPermissions.indexOf(dep), 1);
            }
          });
        });
      }
    });
    return newPermissions;
  };

  const handlePermissionChange = (section: SWSection, resource: SWResource, action: SWAction) => {
    const permissionKey = SWPermissionNameFor(section, resource, action);
    const isChecked = selectedPermissions.includes(permissionKey);
    const updatedPermissions = isChecked
      ? selectedPermissions.filter((p) => p !== permissionKey)
      : [...selectedPermissions, permissionKey];

    const newPermissions = updateDependencies(updatedPermissions);
    setSelectedPermissions(newPermissions);
  };

  const savePermissions = () => {
    console.log(selectedPermissions);
    const updatePermission: EditRoleModel = {
      id: permission.id as number,
      permissions: selectedPermissions,
    };
    updateRolePermissions(updatePermission)
      .then(() => {
        toast.success('Permission updated successfully');
        navigate('/settings/role/list');
      })
      .catch((error) => {
        console.error('Error updating permissions', error);
      });
  };

  useEffect(() => {
    setSelectedPermissions(initialSelectedPermissions);
  }, [initialSelectedPermissions]);

  return (
    <KTCard>
      <div className="card-header">
        <div className="card-title">
          <h3>Update Permissions</h3>
        </div>
      </div>
      <KTCardBody>
        <div className='row mb-5'>
          <label className="col-xl-2 col-lg-2 col-md-3 col-form-label fw-semibold fs-6">Selected Role</label>
          <div className="col-xl-2 col-xl-4 col-lg-4 col-md-5 fv-row fv-plugins-icon-container">
            <label className='form-control'>{permission.name}</label>
          </div>
        </div>

        <table className='table .table-striped table-bordered'>
          <thead className='bg-light'>
            <tr>
              <th scope="col" className='p-2'>Page Name</th>
              <th scope="col" className='p-2'>Permissions</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(SWResource).map((resource, resourceIndex) => (
              <tr key={resourceIndex}>
                <td className='p-2'>{resource}</td>
                <td className='p-2'>
                  {Object.values(SWAction).map((action, actionIndex) => (
                    <div key={actionIndex}>
                      <input
                        type='checkbox'
                        className='check_permission'
                        name=''
                        onChange={() => handlePermissionChange(SWSection.Store, resource, action)}
                        checked={selectedPermissions.includes(SWPermissionNameFor(SWSection.Store, resource, action))}
                      />
                      <span className='ms-2'>{action}</span><br />
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='text-end'>
          <button className='btn btn-primary' onClick={savePermissions}>Save Changes</button>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default PermissionsPage;
