import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {Link, useParams} from 'react-router-dom'
import {downloadPaymentMade, getPaymentMadeDetails} from '../_request'
import {KTCard, KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import {FileResult} from '../../../../core/models/Result'
import {toast} from 'react-toastify'
import {saveAs} from 'file-saver'
import {Loading} from '../../../../core/shared/components/Loading'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

const PaymentMadeDetails = () => {
  const {id} = useParams()
  const {isLoading, data: result} = useQuery(
    ['getPaymentMadeData'],
    async () => {
      return await getPaymentMadeDetails(Number(id))
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  const [activeTab, setActiveTab] = useState<string>('payment_made_summary')

  const handleDownloadPaymentMade = (id: number) => {
    downloadPaymentMade(id).then((file) => {
      let output = file as FileResult

      if (output.data) {
        let url = window.URL.createObjectURL(output.data)
        saveAs(url, output.name)
      } else {
        toast.error(file.exception)
      }
    })
  }

  return (
    <div id='kt_app_content' className='app-content  flex-column-fluid '>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <div className='d-flex flex-column gap-5 gap-lg-5'>
          <KTCard>
            <div className='card-header'>
              <div className='card-title justify-content-between w-100'>
                <h3>Payment Made Summary</h3>
                <div className='d-flex gap-10 align-items-center'>
                  <Link
                    to={`/purchase/payment-made/add/${id}`}
                    className='btn btn-primary btn-sm ms-auto me-lg-n7'
                  >
                    <KTIcon iconName='plus' className='fs-2' /> Add Payment Made
                  </Link>
                  <div
                    className='btn btn-success btn-sm ms-auto ms-2'
                    onClick={() => handleDownloadPaymentMade(Number(id))}
                  >
                    <i className='ki-duotone ki-edit fs-2' /> Download PDF
                  </div>
                </div>
              </div>
            </div>
          </KTCard>

          <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5'>
            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Payment Made Summary (#{result?.paymentNumber})</h3>
                </div>
              </div>

              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>Reference Number</td>
                        <td className='fw-bold text-end'>{result?.referenceNumber}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Payment Date</td>
                        <td className='fw-bold text-end'>
                          {result?.paymentDate
                            ? formatDate(result.paymentDate, 'dd MMM yyyy')
                            : 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Amount Paid</td>
                        <td className='fw-bold text-end'>{formatCurrency(result?.amountPaid)}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Payment Mode </td>
                        <td className='fw-bold text-end'>{result?.paymentModeName}</td>
                      </tr>
                      {/* <tr>
                        <td className='text-muted'>Payment Status</td>
                        <td className='fw-bold text-end'>{result?.paymentModeName}</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Vendor Details</h3>
                </div>
              </div>

              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>Vendor Name</td>
                        <td className='fw-bold text-end'>{result?.vendorDetails?.vendorName}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Vendor Phone</td>
                        <td className='fw-bold text-end'>{result?.vendorDetails?.mobileNumber}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>Vendor Email</td>
                        <td className='fw-bold text-end'>{result?.vendorDetails?.emailAddress}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {result?.paymentModeId !== 1 && (
              <div className='card flex-row-fluid'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3>Bank Details</h3>
                  </div>
                </div>

                <div className='card-body'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                      <tbody className='fw-semibold text-gray-600'>
                        <tr>
                          <td className='text-muted'>Bank Name</td>
                          <td className='fw-bold text-end'>{result?.bankName || 'Bank Name'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='card flex-row-fluid overflow-hidden'>
            <div className='card-header'>
              <div className='card-title'>
                <h3>Bill List</h3>
              </div>
            </div>
            <div className='card-body'>
              <DataTable
                stripedRows
                tableClassName='table table-bordered table-hover mb-0'
                value={result?.billPayments}
                emptyMessage='No Bills found.'
                tableStyle={{minWidth: '60rem'}}
              >
                <Column field='billNumber' header='Bill Number'></Column>
                <Column
                  field='billDate'
                  header='Bill Date'
                  body={(rowData) => formatDate(rowData?.billDate, 'dd MM yyyy')}
                ></Column>
                <Column
                  field='paymentDate'
                  header='Payment Date'
                  body={(rowData) => formatDate(rowData?.paymentDate, 'dd MMM yyyy')}
                ></Column>
                <Column
                  field='amountPaid'
                  header='Total Price'
                  body={(rowData) => formatCurrency(rowData.amountPaid)}
                ></Column>
              </DataTable>
            </div>
            <div className='card-footer text-end'>
              <Link to={`/purchase/payment-made/list`} className='btn btn-primary btn-sm ms-auto'>
                <i className='ki-duotone ki-left fs-2' /> Back
              </Link>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  )
}

export default PaymentMadeDetails
