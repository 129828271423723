import React, {FC, useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import {Filter, KTCard, KTCardBody, PaginationFilter} from '../../../../_metronic/helpers'
import {getProductListForBarcode} from '../product/product-list/core/_requests'
import {ProductBarcodeData, ProductFilter} from '../product/product-list/core/_models'
import {SelectOptionProps} from '../../../core/models/SelectOptionProps'

import {useReactToPrint} from 'react-to-print'
import PrintButton from './PrintButton'
import {getProductGroupList} from '../productGroup/productgroup-list/core/_requests'
import {Color} from 'chart.js'
import clsx from 'clsx'
import {PolishingType} from '../../../core/shared/core/_model'
import {getColorList, getPolishingTypeList} from '../../../core/shared/core/_request'
import {useLocation} from 'react-router-dom'
import {ProductGroup} from '../productGroup/productgroup-list/core/_models'
import {getCategoryList} from '../category/category-list/core/_requests'
import {Category} from '../category/category-list/core/_models'

type PrintBarcodeForm = {
  categoryId?: SelectOptionProps | null
  productGroupId?: SelectOptionProps | null
  polishingTypeIds?: SelectOptionProps[] | null
  colorIds?: SelectOptionProps[] | null
}

const PrintBarcodePage: FC = () => {
  const location = useLocation()
  const selectedIds = location.state?.selectedIds ?? []
  const selectedItems = location.state?.selectedItems ?? []
  const componentRef = useRef<PrintButton>(null)

  const [selectedProducts, setSelectedProducts] = useState<ProductBarcodeData[]>([])

  const [showPrice, setShowPrice] = useState<number>(0)

  const [selectAllColors, setSelectAllColors] = useState(false)
  const [selectAllPolishingType, setSelectAllPolishingType] = useState(false)

  const [categoryList, setCategoryList] = useState<SelectOptionProps[]>([])
  const [productGroupList, setProductGroupList] = useState<SelectOptionProps[]>([])
  const [polishingTypeList, setPolishingTypeList] = useState<SelectOptionProps[]>([])
  const [colorList, setColorList] = useState<SelectOptionProps[]>([])

  const [printBarcodeData, setPrintBarcodeData] = useState<PrintBarcodeForm>({
    categoryId: null,
    productGroupId: null,
    colorIds: null,
    polishingTypeIds: null,
  })

  const handleQuantityChange = (productId: number, newQuantity: number) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId
          ? {...product, quantity: newQuantity > 0 ? newQuantity : 1} // Ensure positive quantity
          : product
      )
    )
  }

  const handleRemoveProduct = (productIndex: number) => {
    setSelectedProducts((prevProducts) => prevProducts.filter((_, index) => index !== productIndex))
  }

  const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,
  }

  const handlePriceChange = (e: any) => {
    setShowPrice(e)
  }

  const printBarcode = (event) => {
    event.preventDefault()
    ;(document.getElementById('printBarcode') as HTMLElement).click()
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  // print barcode
  const loadPrintBlock = () => {
    return (
      <div className='d-none'>
        <button id='printBarcode' onClick={handlePrint}>
          Print this out!
        </button>
        <PrintButton ref={componentRef} products={selectedProducts} pricesep={showPrice} />
      </div>
    )
  }

  const searchProducts = async () => {
    const updatedPaginationFilter: ProductFilter = {
      ...paginationFilter,
      advancedFilter: {
        filters: [
          {field: 'isActive', operator: 'eq', value: true},
          {field: 'productGroupId', operator: 'eq', value: printBarcodeData?.productGroupId?.value},
        ],
        logic: 'and',
      },
      colorIds: printBarcodeData?.colorIds?.map((x) => x.value),
      polishingTypeIds: printBarcodeData?.polishingTypeIds?.map((x) => x.value),
    }

    const products = (await getProductListForBarcode(updatedPaginationFilter)).data
    displayProducts(products)
  }

  const displayProducts = (products: ProductBarcodeData[] = []) => {
    setSelectedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts]

      products.forEach((newProduct) => {
        const existingProductIndex = updatedProducts.findIndex(
          (product) => product.id === newProduct.id
        )

        if (existingProductIndex > -1) {
          // Update quantity if product exists
          updatedProducts[existingProductIndex].quantity =
            (updatedProducts[existingProductIndex].quantity || 1) + (newProduct.quantity || 1)
        } else {
          // Add new product if it doesn't exist
          updatedProducts.push({...newProduct, quantity: newProduct.quantity || 1})
        }
      })

      return updatedProducts
    })

    setSelectAllColors(false)
    setSelectAllPolishingType(false)

    setPrintBarcodeData({
      categoryId: null,
      productGroupId: null,
      colorIds: null,
      polishingTypeIds: null,
    })
  }

  const handleCategoryChange = async (selectedOption: SelectOptionProps) => {
    if (selectedOption) {
      try {
        setPrintBarcodeData({
          ...printBarcodeData,
          categoryId: selectedOption,
        })

        const updatedPaginationFilter: PaginationFilter = {
          ...paginationFilter,
          advancedFilter: {
            filters: [
              {field: 'isActive', operator: 'eq', value: true},
              {
                field: 'CategoryId',
                operator: 'eq',
                value: selectedOption.value,
              },
            ],
            logic: 'and',
          },
        }

        let productGrpArray: any[] = []

        await getProductGroupList(updatedPaginationFilter).then((v) => {
          const result = v.data as ProductGroup[]
          result &&
            result.map((item: ProductGroup) => {
              return productGrpArray.push({value: item.id, label: item.name})
            })
          setProductGroupList(productGrpArray)
        })
      } catch (error) {
        console.error('Error fetching product details:', error)
      }
    }
  }

  const handleProductChange = async (selectedOption: SelectOptionProps) => {
    if (selectedOption) {
      try {
        setPrintBarcodeData({
          ...printBarcodeData,
          productGroupId: selectedOption,
        })
      } catch (error) {
        console.error('Error fetching product details:', error)
      }
    }
  }

  const handleColorChange = (e: SelectOptionProps[]) => {
    setPrintBarcodeData({
      ...printBarcodeData,
      colorIds: e,
    })
  }

  const handlePolishingChange = (e: SelectOptionProps[]) => {
    setPrintBarcodeData({
      ...printBarcodeData,
      polishingTypeIds: e,
    })
  }

  // useEffect(() => {

  //   if (ref.current) {
  //     handlePrint()
  //   }
  // }, [ref.current]);

  useEffect(() => {
    const fetchInitialData = async () => {
      let result: any

      let colorArray: any[] = []

      await getColorList(paginationFilter).then((v) => {
        result = v.data as Color[]
        result
          .filter((x) => x.name != 'NA')
          .map((item: any) => {
            return colorArray.push({value: item.id, label: item.name, colorCode: item.colorCode})
          })
        setColorList(colorArray)
      })

      let polishingTypeArray: any[] = []

      await getPolishingTypeList(paginationFilter).then((v) => {
        result = v.data as PolishingType[]
        result
          .filter((x) => x.name != 'NA')
          .map((item: any) => {
            return polishingTypeArray.push({value: item.id, label: item.name})
          })
        setPolishingTypeList(polishingTypeArray)
      })

      let categoryArray: any[] = []

      getCategoryList(paginationFilter).then((v) => {
        result = v.data as Category[]
        result.map((item: any) => {
          return categoryArray.push({value: item.id, label: item.parentChildCategoryName})
        })
        setCategoryList(categoryArray)
      })

      let productGrpArray: any[] = []

      await getProductGroupList(paginationFilter).then((v) => {
        result = v.data as ProductGroup[]
        result &&
          result.map((item: ProductGroup) => {
            return productGrpArray.push({value: item.id, label: item.name})
          })
        setProductGroupList(productGrpArray)
      })
    }
    fetchInitialData()
  }, [])

  const handleSelectAllColors = (e: any) => {
    const isChecked = e.target.checked
    setSelectAllColors(isChecked)

    if (isChecked) {
      let allColorIds: any[] = []
      colorList
        .filter((x) => x.label != 'NA')
        .map((option) => {
          return allColorIds.push({value: option.value, label: option.label})
        })

      setPrintBarcodeData({
        ...printBarcodeData,
        colorIds: allColorIds,
      })
    } else {
      setPrintBarcodeData({
        ...printBarcodeData,
        colorIds: undefined,
      })
    }
  }

  const handleSelectAllPolishingType = (e: any) => {
    const isChecked = e.target.checked

    setSelectAllPolishingType(isChecked)

    if (isChecked) {
      let allPT: any[] = []
      polishingTypeList
        .filter((x) => x.label != 'NA')
        .map((option) => {
          return allPT.push({value: option.value, label: option.label})
        })

      setPrintBarcodeData({
        ...printBarcodeData,
        polishingTypeIds: allPT,
      })
    } else {
      setPrintBarcodeData({
        ...printBarcodeData,
        polishingTypeIds: undefined,
      })
    }
  }

  useEffect(() => {
    const fetchProducts = async () => {
      let allProducts: ProductBarcodeData[] = []
      for (let id of selectedIds) {
        const updatedPaginationFilter: PaginationFilter = {
          ...paginationFilter,
          advancedFilter: {
            filters: [
              {field: 'isActive', operator: 'eq', value: true},
              {
                field: 'id',
                operator: 'eq',
                value: id,
              },
            ],
            logic: 'and',
          },
        }

        try {
          const products = (await getProductListForBarcode(updatedPaginationFilter)).data
          allProducts = [...allProducts, ...(products as ProductBarcodeData[])]
        } catch (error) {
          console.error(`Error fetching products for id ${id}`, error)
        }
      }

      let newProducts: ProductBarcodeData[] = []
      allProducts.map((item) => {
        selectedItems.map((selected: {productId: number; quantity: number}) => {
          if (item.id === selected.productId) {
            newProducts.push({
              ...item,
              quantity: selected.quantity,
            })
          }
        })
      })

      setSelectedProducts(newProducts as ProductBarcodeData[])
      if (selectedIds != undefined) displayProducts()
    }
    fetchProducts()
  }, [selectedIds !== undefined])

  return (
    <>
      {selectedProducts.length !== 0 ? loadPrintBlock() : ''}

      <KTCard>
        <div className='card-header'>
          <div className='card-title'>
            <h3>Print Barcode</h3>
          </div>
        </div>
        <KTCardBody>
          <div className='row align-items-start'>
            <div className='col-xl-4 col-lg-12 col-md-6 mb-5'>
              <label className='form-label'>Select Category:</label>
              <Select
                name='categoryId'
                className={clsx('form-select-solid ms-1')}
                onChange={(e) => handleCategoryChange(e as SelectOptionProps)}
                options={categoryList}
                placeholder='Select a Category'
                value={printBarcodeData.categoryId}
                isMulti={false}
              />
            </div>

            <div className='col-xl-4 col-lg-12 col-md-6 mb-5'>
              <label className='form-label'>Select Product Group:</label>
              <Select
                name='productGroupId'
                className={clsx('form-select-solid ms-1')}
                onChange={(e) => handleProductChange(e as SelectOptionProps)}
                value={printBarcodeData.productGroupId}
                options={productGroupList}
                placeholder='Select a group'
                isMulti={false}
              />
            </div>

            <div className='col-xl-4 col-lg-12 col-md-6 mb-5'>
              <div className='d-flex justify-content-between'>
                <label className='required form-label'>Color</label>

                <label className='form-check-label ms-5'>
                  <input
                    className='form-check-input ms-7 me-4'
                    type='checkbox'
                    name='allColors'
                    checked={selectAllColors}
                    onChange={handleSelectAllColors}
                  />
                  Select All
                </label>
              </div>

              <Select
                className={clsx('form-select-solid ms-1')}
                name='colorIds'
                options={colorList}
                onChange={(selectedcolor) =>
                  handleColorChange(selectedcolor as SelectOptionProps[])
                }
                value={printBarcodeData.colorIds}
                placeholder='Select Color'
                isMulti={true}
              ></Select>
            </div>

            <div className='col-xl-4 col-lg-12 col-md-6 mb-5'>
              <div className='d-flex justify-content-between'>
                <label className='required form-label'>Polishing Type</label>
                <label className='form-check-label ms-5'>
                  <input
                    className='form-check-input ms-7 me-4'
                    type='checkbox'
                    name='allPolishingType'
                    checked={selectAllPolishingType}
                    onChange={handleSelectAllPolishingType}
                  />
                  Select All
                </label>
              </div>

              <Select
                className={clsx('form-select-solid ms-1')}
                name='polishingTypeIds'
                options={polishingTypeList}
                onChange={(selectedPolishing) =>
                  handlePolishingChange(selectedPolishing as SelectOptionProps[])
                }
                placeholder='Select Polishing Type'
                value={printBarcodeData.polishingTypeIds}
                isMulti={true}
              ></Select>
            </div>

            <div className='col-xl-4 col-lg-12 col-md-6 mb-5 '>
              <label className='form-label'>Price Separator</label>
              <input
                type='text'
                name='priceseparator'
                className='form-control'
                onChange={(newValue: any) => {
                  handlePriceChange(newValue.target.value)
                }}
              ></input>
            </div>
          </div>
        </KTCardBody>
        <div className='card-footer d-flex gap-3 justify-content-end'>
          <button className='btn btn-primary' id='btnSearch'>
            Clear Filters
          </button>
          <button className='btn btn-primary' onClick={searchProducts}>
            Search & Add
          </button>
        </div>
      </KTCard>

      {selectedProducts.length > 0 && (
        <KTCard className='mt-5'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Selected Products</h3>
            </div>
          </div>
          <KTCardBody>
            <div>
              <table className='table'>
                <thead style={{height: '40px'}}>
                  <th className='bg-light p-4'>Product Name</th>
                  <th className='bg-light p-4'>Quantity</th>
                  <th className='bg-light p-4'>Actions</th>
                </thead>
                <tbody>
                  {selectedProducts.map((product, index) => (
                    <tr key={index}>
                      <td>{product.name}</td>
                      <td>
                        <input
                          type='number'
                          className='form-control'
                          value={product.quantity || 1}
                          onChange={(e) =>
                            handleQuantityChange(product.id, parseInt(e.target.value, 10))
                          }
                          min={1}
                        />
                      </td>
                      <td>
                        <button
                          type='button'
                          data-repeater-delete=''
                          className='btn btn-sm btn-icon btn-light-danger mt-2'
                          onClick={() => handleRemoveProduct(index)}
                        >
                          <i className='ki-duotone ki-cross fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                          </i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </KTCardBody>
          <div className='card-footer text-end'>
            {selectedProducts.length > 0 && (
              <div className='d-xl-flex align-items-center justify-content-end'>
                <button
                  type='button'
                  className='btn btn-primary me-5 mb-2 btn btn-primary'
                  onClick={printBarcode}
                >
                  Print
                </button>
              </div>
            )}
            {loadPrintBlock()}
          </div>
        </KTCard>
      )}
    </>
  )
}

export default PrintBarcodePage
