import React, {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import {format} from 'date-fns'
import {toZonedTime} from 'date-fns-tz'
import {Filter, Vendor} from '../_models'
import {AutoComplete, AutoCompleteCompleteEvent} from 'primereact/autocomplete'
import {useQuery} from 'react-query'
import {getVendorList} from '../../paymentMade/_request'
import {Field} from 'formik'
import CustomAsyncSelect from '../../../../core/shared/components/CustomAsyncSelect'

const BillAdvancedSearch = ({
  refetch,
  onFilterQueryChange,
}: {
  refetch: () => void
  onFilterQueryChange: (query: any) => void
}) => {
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false)
  const [filterStatus, updateFilterStatus] = useState<boolean | undefined>()
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const [filterName, updateFilterName] = useState<string>('')
  const [filterBillNumber, updateFilterBillNumber] = useState<string>('')
  const [filterBillDateFrom, setFilterBillDateFrom] = useState<string | undefined | Date>(undefined)
  const [filterBillDateTo, setFilterBillDateTo] = useState<string | undefined | Date>(undefined)

  const [filterQuery, setFilterQuery] = useState({})
  const [items, setItems] = useState<string[]>([])

  const {data: vendorList, isLoading: vendorListLoading} = useQuery({
    queryKey: ['vendorList'],
    queryFn: () =>
      getVendorList({
        pageSize: 100,
      }),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  const search = (event: AutoCompleteCompleteEvent) => {
    if (vendorListLoading) return

    setItems(
      vendorList?.data
        ?.filter((item: any) => item?.printName?.toLowerCase()?.includes(event.query.toLowerCase()))
        ?.map((item) => item?.printName) || ['No vendor found']
    )
  }

  const updateSearchFilters = () => {
    let filters: Filter[] = []
    if (filterStatus != undefined) {
      filters.push({
        field: 'orderStatusId',
        operator: 'eq',
        value: filterStatus,
      })
    }

    if (filterName != undefined && filterName != '') {
      filters.push({
        field: 'vendor.printName',
        operator: 'contains',
        value: filterName,
      })
    }

    if (filterBillNumber != undefined && filterBillNumber != '') {
      filters.push({
        field: 'billNumber',
        operator: 'contains',
        value: filterBillNumber,
      })
    }

    if (filterBillDateFrom || filterBillDateFrom) {
      let orderDateFilters: Filter[] = []

      if (filterBillDateFrom !== undefined) {
        const fromDate = toZonedTime(new Date(filterBillDateFrom as Date), 'Asia/Kolkata')
        orderDateFilters.push({
          field: 'billDate',
          operator: 'gte',
          value: format(fromDate, 'yyyy-MM-dd 00:00:00'),
        })
      }

      if (filterBillDateTo !== undefined) {
        const toDate = toZonedTime(new Date(filterBillDateTo as Date), 'Asia/Kolkata')

        orderDateFilters.push({
          field: 'billDate',
          operator: 'lte',
          value: format(toDate, 'yyyy-MM-dd 23:59:59'),
        })
      }

      filters.push({
        filters: orderDateFilters,
        logic: 'and',
      })
    }

    if (filters.length > 1) {
      const newFilterQuery = {
        ...filterQuery,
        advancedFilter: {
          filters: filters,
          logic: 'and',
        },
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else if (filters.length === 1) {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: filters[0],
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: undefined,
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    }
  }

  const clearFilters = () => {
    updateFilterBillNumber('')
    updateFilterName('')
    setFilterBillDateFrom(undefined)
    setFilterBillDateTo(undefined)
    setFilterQuery({
      keyword: '',
      advancedFilter: undefined,
    })
    onFilterQueryChange({})
    refetch()
  }

  const handleDateChange = (selectedDates: Date[], dateName: string) => {
    if (dateName === 'from') {
      if (selectedDates[0] === undefined) {
        setFilterBillDateFrom('')
      } else {
        setFilterBillDateFrom(selectedDates[0])
      }
    } else if (dateName === 'to') {
      if (selectedDates[0] === undefined) {
        setFilterBillDateTo('')
      } else {
        setFilterBillDateTo(selectedDates[0])
      }
    }
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header justify-content-between align-items-center'>
          <div className='card-title'>
            <h3>Bill Filter</h3>
          </div>
          <button className='btn' onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
            {advancedSearchVisible ? (
              <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
            ) : (
              <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
            )}
          </button>
        </div>
        {advancedSearchVisible && (
          <>
            <div className='card-body'>
              <div className='row g-5 mb-5'>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Bill Number</label>

                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Bill Number'
                    autoComplete='off'
                    name='orderNumber'
                    value={filterBillNumber}
                    onChange={(e) => updateFilterBillNumber(e.target.value)}
                  />
                </div>

                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Vendor Name</label>
                  <div style={{borderRadius: '4px', border: '1px solid #cccccc', padding: '0'}}>
                    <AutoComplete
                      value={filterName}
                      suggestions={items}
                      minLength={3}
                      placeholder='Enter Vendor Name'
                      showEmptyMessage={true}
                      dropdown
                      completeMethod={search}
                      emptyMessage='No vendor found'
                      name={'vendorName'}
                      onChange={(e) => updateFilterName(e.value)}
                      onSelect={(e) => updateFilterName(e.value)}
                      onUnselect={() => updateFilterName('')}
                      className='p-autocomplete p-autocomplete-input p-inputtext p-component p-autocomplete-dd-input'
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Bill Date</label>
                  <div className='row g-3'>
                    <div className='col'>
                      <Flatpickr
                        options={{dateFormat: 'd-m-Y'}}
                        className='form-control form-control-solid'
                        placeholder='From'
                        onChange={(selectedDates) => handleDateChange(selectedDates, 'from')}
                        value={filterBillDateFrom || ''}
                      />
                    </div>
                    <div className='col'>
                      <Flatpickr
                        options={{dateFormat: 'd-m-Y'}}
                        className='form-control form-control-solid'
                        placeholder='To'
                        onChange={(selectedDates) => handleDateChange(selectedDates, 'to')}
                        value={filterBillDateTo || ''}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex gap-3 justify-content-end'>
              <button className='btn btn-primary' id='btnSearch' onClick={clearFilters}>
                Clear Filters
              </button>
              <button className='btn btn-primary' id='btnSearch' onClick={updateSearchFilters}>
                Search
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default BillAdvancedSearch
