import React, { FC } from 'react'
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { Result, FileResult } from '../../../../core/models/Result';
import { formatCurrency } from '../../../../core/utils/helpers';
import { Payment } from '../core/_model';


type Props = {
  isPaymentLoading: boolean;
  data: Result | Payment;
};
const PaymentDetailPage: FC<Props> = ({ isPaymentLoading, data }) => {
  const paymentData = data as Payment;
  const formatDate = (rowData: any, dateFormat: string) => {
    const date = new Date(rowData);
    return format(date, dateFormat);
  };
  const navigate = useNavigate()
  const API_URL = process.env.REACT_APP_STORE_API_URL
  const GENERATE_PDF_URL = `${API_URL}/saleorders/saleorderpayment/downloadpdf`

  const priceListGeneratePdf = async (id: number): Promise<Result | FileResult> => {
    return await axios
      .get(`${GENERATE_PDF_URL}/${id}`, {
        responseType: 'blob'
      },)
      .then((response: AxiosResponse<any>) => {
        let filename = response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          .replace('filename=', '')
          .trim();

        var result: FileResult = {
          data: response.data,
          name: filename
        }

        return result
      })
      .catch((err: Result) => {
        return err
      })
  }

  const priceListpdf = (soId) => {
    priceListGeneratePdf(soId).then(file => {
      let output = file as FileResult;
      let url = window.URL
        .createObjectURL(output.data);
      saveAs(url, output.name);
    });

  }
  return (
    <>
      <div className='card mb-5'>
        <div className="card-body d-flex justify-content-between align-items-center">
          <h3>Payment Details</h3>
        <button
          type='button'
          className='btn btn-primary'
          onClick={() => { priceListpdf(paymentData.id); }}
        >
          Download PDF
        </button>
      </div>
      </div>

      <div className="d-flex flex-column flex-xl-row gap-5 gap-lg-5">
        <div className="card flex-row-fluid">
          <div className='card-header'>
            <div className="card-title">
              <h3>Payment Details (#{paymentData.paymentNumber ? paymentData.paymentNumber : ' -'})</h3>
            </div>
          </div>

          <div className="card-body pt-0">
            <div className="table-responsive">

              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody className="fw-semibold text-gray-600">
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-calendar fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>{" "}
                        Date Added
                      </div>
                    </td>


                    <td className="fw-bold text-end">
                      {paymentData.paymentDate ? formatDate(paymentData.paymentDate, 'dd MMM yyyy') : 'No Date available'}

                    </td>
                  </tr>

                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-wallet fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </i>{" "}
                        Amount Received
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {paymentData.amountReceived ? formatCurrency(paymentData.amountReceived) : '₹ 0.0'}

                    </td>
                  </tr>

                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-wallet fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </i>{" "}
                        Amount Received
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {paymentData.paymentModeName ? paymentData.paymentModeName : '-'}
                    </td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>

        </div>

        <div className="card flex-row-fluid">
          <div className='card-header'>
            <div className="card-title">
              <h3>Customer Details</h3>
            </div>
          </div>



          <div className="card-body pt-0">
            <div className="table-responsive">
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody className="fw-semibold text-gray-600">
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-profile-circle fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </i>{" "}
                        Customer
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      <div className="d-flex align-items-center justify-content-end">

                        <label

                          className="text-gray-600 text-hover-primary"
                        >
                          {paymentData.customerDetailForPaymentDto?.customerName ? paymentData.customerDetailForPaymentDto?.customerName : ' - '}
                        </label>

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-sms fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>{" "}
                        Email
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      <a
                        className="text-gray-600 text-hover-primary"
                      >
                        {paymentData.customerDetailForPaymentDto?.email ? paymentData.customerDetailForPaymentDto?.email : ' - '}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-phone fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>{" "}
                        Phone
                      </div>
                    </td>
                    <td className="fw-bold text-end">{paymentData.customerDetailForPaymentDto?.mobileNumber ? paymentData.customerDetailForPaymentDto?.mobileNumber : ' - '}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
      {paymentData.saleOrderPayments?.length == 0 ? null :
        <KTCard className='mt-5'>
          <div className="card-header">
            <div className="card-title">
              <h3>Order Details</h3>
            </div>
          </div>
          <KTCardBody className='flex-row-fluid overflow-hidden pt-0'>


            <div className="table-responsive">

              <table className="table align-middle table-row-dashed gy-5 mb-0">
                <thead>
                  <tr className="text-start text-gray-500 fw-bold text-uppercase gs-0">
                    <th className="min-w-175px">Order Number</th>
                    <th className="min-w-70px text-end">Payment Date</th>
                    <th className="min-w-100px text-end">Amount Received</th>
                  </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">

                  {paymentData?.saleOrderPayments?.map((soData, index) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ms-5">
                            {soData.saleOrderNumber}
                          </div>
                        </div>
                      </td>
                      <td className="text-end">{formatDate(soData?.paymentDate, 'dd MMM yyyy')} </td>
                      <td className="text-end">{formatCurrency(soData.amountReceived)}</td>

                    </tr>
                  ))
                  }

                </tbody>
              </table>

            </div>


          </KTCardBody>
          <div className="card-footer text-end">
          <button
              type='button'
              className='btn btn-primary'
              onClick={() => navigate('/sales/payment/list')}
            >
            <i className='ki-duotone ki-left fs-2' /> Back
            </button>
          </div>
        </KTCard>

      }



    </>
  )
}

export default PaymentDetailPage
