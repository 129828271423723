// src/core/shared/components/CustomDatePicker.tsx
import React, {useRef} from 'react'
import Flatpickr from 'react-flatpickr'
import {useField} from 'formik'
import clsx from 'clsx'
import 'flatpickr/dist/flatpickr.css'

interface CustomDatePickerProps {
  name: string
  placeholder?: string
  className?: string
  required?: boolean
  disabled?: boolean
  options?: any
}

const CustomFlatPicker: React.FC<CustomDatePickerProps> = ({
  name,
  placeholder = 'Pick Date',
  className = '',
  required = false,
  disabled = false,
  options = {},
}) => {
  const fp = useRef<any>(null)
  const [field, meta, helpers] = useField(name)

  const defaultOptions = {
    altInput: true,
    altFormat: 'd-m-Y',
    dateFormat: 'd-m-Y',
    allowInput: false,
    ...options,
  }

  return (
    <>
      <div className='date-picker-wrapper'>
        <Flatpickr
          className={clsx('form-control', className, {
            'is-invalid': meta.touched && meta.error,
          })}
          placeholder={placeholder}
          options={defaultOptions}
          value={field.value === '' ? '' : field.value}
          onChange={([date]) => {
            helpers.setValue(date)
          }}
          ref={fp}
          disabled={disabled}
          required={required}
        />
        {!disabled && (
          <button
            type='button'
            className='date-picker-clear'
            onClick={() => {
              if (!fp?.current?.flatpickr) return
              fp.current.flatpickr.clear()
              helpers.setValue('')
            }}
          >
            ×
          </button>
        )}
      </div>
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{meta.error}</span>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomFlatPicker
