import {Route, Routes, Outlet} from 'react-router-dom'

import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import BankAccountList from './pages/BankAccountList'
import BankAccountAddPage from './pages/BankAccountAddPage'
import BankAccountEditPage from './pages/BankAccountEditPage'
import BankAccountDetails from './pages/BankAccountDetails'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BankAccountPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}> Bank Account </PageTitle>
              <BankAccountList />
            </>
          }
        />

        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}> Add Bank Account </PageTitle>
              <BankAccountAddPage />
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}> Edit Bank Account </PageTitle>
              <BankAccountEditPage />
            </>
          }
        />
        <Route
          path='details/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}> Bank Account Details </PageTitle>
              <BankAccountDetails />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default BankAccountPage
