import {FC, useEffect, useState} from 'react'
import {useImmer} from 'use-immer'
import * as Yup from 'yup'
import {useNavigate, useParams} from 'react-router-dom'
import {Field, FormikProvider, useFormik} from 'formik'
import {toast} from 'react-toastify'
import {PaginationFilter, KTSVG, NewLine} from '../../../../../_metronic/helpers'
import {Result} from '../../../../core/models/Result'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import {Loading} from '../../../../core/shared/components/Loading'
import {getSalesOrderForEditById, getTaxedSalesOrderList} from '../../salesOrder/core/_requests'
import {
  CreateInvoiceItem,
  CreateInVoicePayload,
  OtherCharge,
  SaleOrderEditResponse,
  SaleOrderForInvoice,
} from '../core/_models'
import {
  createInvoice,
  getCustomerDetailsForSOById,
  getSalesOrderInvoiceById,
} from '../core/_requests'
import {SaleOrderDto, SalesOrder, SalesOrderItems} from '../../salesOrder/core/_models'
import {getSalesOrderSetting} from '../../../settings/core/_requests'
import CustomAsyncSelect from '../../../../core/shared/components/CustomAsyncSelect'
import {
  Customer,
  CustomerAddressForSO,
  CustomerDetailforSOModel,
} from '../../customer/customer-list/core/_models'
import {getCustomerList} from '../../customer/customer-list/core/_requests'
import ProductSearchbar from '../../../../core/shared/components/ProductSearchbar'
import {Product} from '../../../catalog/product/product-list/core/_models'
import {getPlaceOfSupplyList, getTermList} from '../../../../core/shared/core/_request'
import {SelectOptionProps} from '../../../../core/models/SelectOptionProps'
import {PlaceOfSupply, Term} from '../../../../core/shared/core/_model'
import {formatCurrency, formatPercentage} from '../../../../core/utils/helpers'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import OtherChargesTable from '../../../../core/shared/components/OtherChargesTable'
import {InputNumber} from 'primereact/inputnumber'
import ProductSelection from '../../../../core/shared/components/ProductSelection'
import {useQuery} from 'react-query'
import CalendarInput from '../../../../core/shared/components/CalendarInput'
import {camelize} from '../../../../core/utils/StringHelpers'
import clsx from 'clsx'
import {addDays, format, parseISO} from 'date-fns'
const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}
const invoiceSchema = Yup.object().shape({
  customerId: Yup.number().required('Please select customer'),
  invoiceDate: Yup.string().required('Please enter invoice date'),
  // .max(new Date(), 'Invoice Date cannot be greater than the current date')
  // .min(new Date('1090-01-01'), 'Invoice Date cannot be earlier than January 1, 1090'),
})

const initialValues = {
  customerId: 0,
  saleOrderId: 0,
  invoiceDate: format(new Date(), 'yyyy-MM-dd'),
  dueDate: '',
  termId: 0,
  shippingAddressId: 0,
  placeOfSupplyId: 0,
  itemList: [
    {
      productId: 0,
      quantity: 0,
      productPrice: 0,
      taxPercent: 0,
      isDeleted: true,
    },
  ],
  otherCharges: [
    {
      id: 0,
      label: '',
      amount: 0,
      isDeleted: true,
    },
  ],
  notes: '',
  discountPercent: 0,
}
const InvoiceAddPage: FC = () => {
  const {id: saleId} = useParams()
  const checkSaleID = Boolean(saleId)
  const navigate = useNavigate()

  const [otherCharges, setOtherCharges] = useImmer<OtherCharge[]>([])
  const [saleOrderItems, updateSaleOrderItems] = useImmer<CreateInvoiceItem[]>([])
  const [saleOrderList, setSaleOrderList] = useState<SalesOrder[]>([])
  const [customerData, setCustomerData] = useState<CustomerDetailforSOModel>()
  const [saleOrderDetail, updateSaleOrderDetail] = useState<SaleOrderEditResponse>()
  const [shippingAddressOptions, setShippingAddressOptions] = useState<SelectOptionProps[]>([])

  const [subTotal, setSubTotal] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [totalTaxAmount, setTotalTaxAmount] = useState(0)
  const [roundOff, setRoundOff] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)

  const [errorMessage, updateErrorMessage] = useState<string>('')

  useEffect(() => {
    if (checkSaleID) {
      getSalesOrderForEditById(saleId).then((v) => {
        const result = v as SaleOrderEditResponse

        updateSaleOrderDetail(result)
        const saleOrderItems = result.itemList

        updateSaleOrderItems((soitems) => {
          soitems.splice(0, soitems.length)
          saleOrderItems.forEach((item, index) => {
            soitems.push({
              rowNumber: index,
              isDeleted: false,
              productId: item.productId as number,
              productName: item.productName,
              quantity: item.quantity,
              productPrice: item.productPrice,
              subTotal: item.subTotal,
              discountPercent: item.discountPercent,
              discountAmount: item.discountAmount,
              total: item.total,
              taxPercent: item.taxPercent,
              taxAmount: item.taxAmount,
              totalInclusiveTax: item.totalInclusiveTax,
            })
          })
        })

        const othercharges = result.otherCharges
        setOtherCharges((ocitems) => {
          ocitems.splice(0, ocitems.length)
          othercharges.forEach((item, index) => {
            ocitems.push({
              id: 0,
              rowNumber: index + 1,
              isDeleted: false,
              label: item.label as string,
              amount: item.amount,
            })
          })
        })

        getCustomerDetailsForSOById(result?.customerId).then((result: CustomerDetailforSOModel) => {
          setCustomerData(result)

          // Update other customer-related fields
          formik.setFieldValue('termId', result.termId)
          formik.setFieldValue('discountPercent', result.discountPercent)
          handleDiscountChange(result.discountPercent)

          // Update shipping address options
          const options =
            result.addressList?.map(
              ({customerAddressId, displayAddress}: CustomerAddressForSO) => ({
                value: customerAddressId,
                label: displayAddress,
              })
            ) || []
          setShippingAddressOptions(options)

          // Clear shipping address selection

          // Validate shipping address availability
          if (!result.addressList || result.addressList.length === 0) {
            updateErrorMessage('Shipping address is required')
          } else {
            formik.setFieldValue('shippingAddressId', options[0].value)
            updateErrorMessage('')
          }
        })
      })
    }
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: checkSaleID
      ? {
          customerId: saleOrderDetail?.customerId,
          saleOrderId: saleId,
          invoiceDate: format(new Date(), 'yyyy-MM-dd'),
          dueDate: saleOrderDetail?.dueDate,
          termId: saleOrderDetail?.termId,
          shippingAddressId: saleOrderDetail?.shippingAddressId,
          placeOfSupplyId: saleOrderDetail?.placeOfSupplyId,
          itemList: saleOrderItems,
          otherCharges: otherCharges,
          notes: '',
          discountPercent: saleOrderDetail?.discountPercent,
        }
      : initialValues,
    validationSchema: invoiceSchema,
    onSubmit: async (formValues, {setFieldError, setSubmitting}) => {
      setSubmitting(true)
      try {
        updateErrorMessage('')
        if (saleOrderItems.filter((item) => !item.isDeleted).length === 0) {
          updateErrorMessage('Alteast one product is required')
          window.scrollTo(0, 0)
          return
        }
        if (saleOrderItems.filter((item) => !item.isDeleted && item.productId == 0).length > 0) {
          updateErrorMessage('Select Valid Product')
          window.scrollTo(0, 0)
          return
        }
        if (saleOrderItems.filter((item) => !item.isDeleted && item.quantity == 0).length > 0) {
          updateErrorMessage('Please ensure that the quantity cannot be zero')
          window.scrollTo(0, 0)
          return
        }
        if (grandTotal <= 0) {
          updateErrorMessage(
            'Please ensure that the total amount is greater than or equal to zero.'
          )
          window.scrollTo(0, 0)

          return
        }

        let result: Result
        const requestData: CreateInVoicePayload = {
          customerId: Number(formValues?.customerId),
          saleOrderId: Number(formValues?.saleOrderId),
          invoiceDate: formValues?.invoiceDate,
          dueDate: formValues?.dueDate as string,
          termId: Number(formValues?.termId),
          shippingAddressId: Number(formValues?.shippingAddressId),
          placeOfSupplyId: Number(formValues?.placeOfSupplyId),
          itemList: saleOrderItems
            .filter((x) => x.isDeleted == false)
            .map((item) => ({
              productId: Number(item.productId),
              quantity: item.quantity ?? 0,
              taxPercent: item.taxPercent ?? 0,
              productPrice: item.productPrice ?? 0,
              isDeleted: item.isDeleted || false,
            })),
          otherCharges: otherCharges
            .filter((x) => x.isDeleted == false)
            .map((charge) => ({
              id: charge.id || 0,
              label: charge.label || '',
              amount: charge.amount,
              isDeleted: charge.isDeleted || false,
            })),
          notes: formValues?.notes,
          discountPercent: Number(formValues?.discountPercent),
        }

        result = await createInvoice(requestData)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('Invoice created successfully!')
          navigate('/sales/invoice/list')
        } else {
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage)
            )
          }
          if (result?.succeeded === false) {
            result.messages.map((message) => toast.error(message))
          }
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  const {data: salesOrderSetting, isLoading: isSOSLoading} = useQuery(['sales-order-setting'], () =>
    getSalesOrderSetting()
  )

  const {
    data: termListResponse,
    isLoading: isTermListLoading,
    isError: isTermListError,
  } = useQuery(['termList'], () => getTermList(paginationFilter))

  useEffect(() => {
    if (formik.values.termId && termListResponse?.data) {
      const term = termListResponse.data.find((x) => x.id === formik.values.termId)

      if (term?.numberOfDays != null) {
        const invoiceDateString = formik.values.invoiceDate || format(new Date(), 'yyyy-MM-dd') // Fallback to current date
        const invoiceDate = parseISO(invoiceDateString) // Parse orderDate
        const updatedDueDate = format(addDays(invoiceDate, term.numberOfDays), 'yyyy-MM-dd')

        // Update dueDate only if it has changed
        if (formik.values.dueDate !== updatedDueDate) {
          formik.setFieldValue('dueDate', updatedDueDate)
        }
      }
    }
  }, [formik.values.termId, formik.values.invoiceDate, termListResponse])

  const {
    data: placeOfSupplyListResponse,
    isLoading: isPlaceOfSupplyLoading,
    isError: isPoSListError,
  } = useQuery(['placeofsupply'], () => getPlaceOfSupplyList(paginationFilter))

  const onProductsChange = (product: Product, quantity: number = 1) => {
    updateSaleOrderItems((items) => {
      const existingItem = items.find((item) => item.productId === product.id)

      if (existingItem) {
        // Update existing item
        existingItem.quantity = (existingItem.quantity ?? 0) + quantity

        const {productPrice, discountPercent, taxPercent} = existingItem
        const totals = calculateItemTotals(
          productPrice ?? 0,
          existingItem.quantity,
          discountPercent ?? 0,
          taxPercent ?? 0
        )

        Object.assign(existingItem, totals)
      } else {
        // Add a new item
        const productPrice = getPriceForCustomer(product)
        const discountPercent = formik.values.discountPercent ?? 0
        const taxPercent = product.taxRateValue ?? 0

        const totals = calculateItemTotals(
          Number(productPrice),
          quantity,
          discountPercent,
          taxPercent
        )

        items.push({
          rowNumber: items.length,
          productId: product.id,
          productName: product.name,
          quantity: quantity,
          productPrice,
          discountPercent,
          taxPercent,
          isDeleted: false,
          ...totals,
        })
      }
    })
  }

  const onSaleOrderChange = async (e: any) => {
    if (e === null) {
      updateSaleOrderItems([])
    } else {
      await getSalesOrderForEditById(e.value).then((v) => {
        const result = v as SaleOrderEditResponse

        updateSaleOrderDetail(result)
        const saleOrderItems = result.itemList

        updateSaleOrderItems((soitems) => {
          soitems.splice(0, soitems.length)
          saleOrderItems.forEach((item, index) => {
            soitems.push({
              rowNumber: index,
              isDeleted: false,
              productId: item.productId as number,
              productName: item.productName,
              quantity: item.quantity,
              productPrice: item.productPrice,
              subTotal: item.subTotal,
              discountPercent: item.discountPercent,
              discountAmount: item.discountAmount,
              total: item.total,
              taxPercent: item.taxPercent,
              taxAmount: item.taxAmount,
              totalInclusiveTax: item.totalInclusiveTax,
            })
          })
        })

        const othercharges = result.otherCharges
        setOtherCharges((ocitems) => {
          ocitems.splice(0, ocitems.length)
          othercharges.forEach((item, index) => {
            ocitems.push({
              id: 0 as number,
              rowNumber: (index + 1) as number,
              isDeleted: false,
              label: item.label as string,
              amount: item.amount,
            })
          })
        })
      })
    }
  }

  const onCustomerChange = async (e: any) => {
    getCustomerDetailsForSOById(e.value).then((result: CustomerDetailforSOModel) => {
      setCustomerData(result)

      // Update other customer-related fields
      formik.setFieldValue('termId', result.termId)
      formik.setFieldValue('discountPercent', result.discountPercent)
      handleDiscountChange(result.discountPercent)

      // Update shipping address options
      const options =
        result.addressList?.map(({customerAddressId, displayAddress}: CustomerAddressForSO) => ({
          value: customerAddressId,
          label: displayAddress,
        })) || []
      setShippingAddressOptions(options)

      // Clear shipping address selection

      // Validate shipping address availability
      if (!result.addressList || result.addressList.length === 0) {
        updateErrorMessage('Shipping address is required')
      } else {
        formik.setFieldValue('shippingAddressId', options[0].value)
        updateErrorMessage('')
      }
    })

    let result: any
    const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: {
        field: 'customerId',
        operator: 'eq',
        value: e.value,
      },
    }

    await getTaxedSalesOrderList(updatedPaginationFilter).then((v) => {
      result = v.data as SaleOrderDto[]
      let saleOrderArray: any[] = []
      result.map((item) => {
        saleOrderArray.push({value: item.id, label: item.orderNumber})
      })
      setSaleOrderList(saleOrderArray)
    })
  }

  const termOptions: SelectOptionProps[] =
    termListResponse?.data?.map(({id, name}: Term) => ({
      value: id as number,
      label: String(name),
    })) || []

  const placeOfSupplyOptions: SelectOptionProps[] =
    placeOfSupplyListResponse?.data?.map(({id, name, code}: PlaceOfSupply) => ({
      value: id as number,
      label: `${code} - ${name}`,
    })) || []

  const handleDelete = (e: React.ChangeEvent<HTMLInputElement>, rowNumber: number) => {
    updateSaleOrderItems((draft) => {
      return draft.filter((item) => item.rowNumber !== rowNumber)
    })
  }

  const updateSaleOrderItem = (rowNumber: number, updates: Partial<SalesOrderItems>) => {
    updateSaleOrderItems((items) => {
      const item = items.find((t) => t.rowNumber === rowNumber)
      if (item) {
        Object.assign(item, updates)
      }
    })
  }

  const updateItemTotal = (rowNumber: number) => {
    updateSaleOrderItems((items) => {
      const item = items.find((t) => t.rowNumber === rowNumber)
      if (item) {
        const {productPrice, quantity, discountPercent, taxPercent} = item

        const totals = calculateItemTotals(
          productPrice ?? 0,
          quantity ?? 0,
          discountPercent ?? 0,
          taxPercent ?? 0
        )

        Object.assign(item, totals)
      }
    })
  }

  const handleDiscountChange = (discountPercent: number) => {
    updateSaleOrderItems((items) =>
      items.map((item) => {
        const {productPrice, quantity, taxPercent} = item

        const totals = calculateItemTotals(
          productPrice ?? 0,
          quantity ?? 0,
          discountPercent,
          taxPercent ?? 0
        )

        return {
          ...item,
          ...totals,
          discountPercent,
        }
      })
    )
  }

  const calculateItemTotals = (
    unitPrice: number,
    quantity: number,
    discountPercent: number = 0,
    taxPercent: number = 0
  ) => {
    const subTotal = parseFloat(((unitPrice || 0) * (quantity || 0)).toFixed(2))
    const discountAmount = parseFloat(((subTotal * discountPercent) / 100).toFixed(2))
    const tempTotal = parseFloat((subTotal - discountAmount).toFixed(2))
    const taxAmount = parseFloat(((tempTotal * taxPercent) / 100).toFixed(2))
    const totalInclusiveTax = parseFloat((tempTotal + taxAmount).toFixed(2))

    return {
      subTotal,
      discountAmount,
      tempTotal,
      taxAmount,
      totalInclusiveTax,
    }
  }

  const unitPriceBody = (rowData: SalesOrderItems) => {
    return (
      <InputNumber
        inputStyle={{maxWidth: 100}}
        value={rowData.productPrice}
        onValueChange={(e) => {
          const productPrice = e.value as number
          updateSaleOrderItem(rowData.rowNumber, {productPrice})
          updateItemTotal(rowData.rowNumber)
        }}
        mode='currency'
        currency='INR'
        locale='en-IN'
      />
    )
  }

  const quantityBody = (rowData: SalesOrderItems) => {
    return (
      <InputNumber
        inputStyle={{maxWidth: 80}}
        value={rowData.quantity}
        onValueChange={(e) => {
          const quantity = e.value as number
          updateSaleOrderItem(rowData.rowNumber, {quantity})
          updateItemTotal(rowData.rowNumber)
        }}
      />
    )
  }

  const actionBody = (rowData: SalesOrderItems) => {
    return (
      <button
        type='button'
        className='btn btn-sm btn-icon btn-light-danger mt-2'
        onClick={(e: any) => {
          handleDelete(e, rowData.rowNumber)
        }}
      >
        <i className='ki-duotone ki-cross fs-1'>
          <span className='path1' />
          <span className='path2' />
        </i>
      </button>
    )
  }

  useEffect(() => {
    let tempSubTotal = 0
    let tempDiscount = 0
    let tempTaxAmount = 0
    let tempOtherCharges = 0

    // Calculate totals from saleOrderItems
    saleOrderItems
      .filter((t) => !t.isDeleted)
      .forEach((item) => {
        tempSubTotal += item.subTotal ?? 0
        tempDiscount += item.discountAmount ?? 0
        tempTaxAmount += item.taxAmount ?? 0
      })

    // Calculate totals from otherCharges
    otherCharges
      .filter((t) => !t.isDeleted)
      .forEach((charge) => {
        tempOtherCharges += charge.amount ?? 0
      })

    const tempTotal = tempSubTotal + tempOtherCharges - tempDiscount + tempTaxAmount
    const roundedTotal = Math.round(tempTotal)

    setSubTotal(tempSubTotal)
    setTotalDiscount(tempDiscount)
    setTotalTaxAmount(tempTaxAmount)
    setRoundOff(roundedTotal - tempTotal)
    setGrandTotal(roundedTotal)
  }, [saleOrderItems, otherCharges])

  const getPriceForCustomer = (product: Product): number => {
    switch (customerData?.rateId) {
      case 1:
        return Number(product.spWholeSeller)
      case 2:
        return product.spSemiWholeSeller ?? 0
      default:
        return product.spRetail ?? 0
    }
  }

  if (isSOSLoading || isTermListLoading || isPlaceOfSupplyLoading) return <Loading />
  return (
    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_invoice_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='d-flex flex-column flex-row-fluid gap-5 gap-lg-5 mb-7'>
          {errorMessage != '' && <div className='alert alert-danger'>{errorMessage}</div>}

          <div className='card custom-position'>
            <div className='card-header'>
              <div className='card-title'>
                <h3>Order Details</h3>
              </div>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4'>
                  <label className='form-label required'>Customer</label>
                </div>
                <div className='col-xxl-6 col-xl-8 col-lg-9 col-md-8'>
                  {checkSaleID ? (
                    <Field
                      className='form-control'
                      disabled={true}
                      name={`customerId`}
                      value={saleOrderDetail?.customerName}
                    ></Field>
                  ) : (
                    <Field
                      className='form-select-solid-solid'
                      component={CustomAsyncSelect<Customer>}
                      {...formik.getFieldProps(`customerId`)}
                      name={`customerId`}
                      loadOptionFunc={getCustomerList}
                      searchByField='printName'
                      labelField='printName'
                      disabledisActiveFilter={true}
                      onDropDownChange={(newValue: any) => {
                        onCustomerChange(newValue)
                        formik.setFieldValue('customerId', newValue?.value)
                      }}
                    ></Field>
                  )}
                  <div className='text-muted fs-7'>Select the Customer.</div>
                  {formik.touched.customerId && formik.errors.customerId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.customerId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {customerData ? (
                <div className='row mt-3'>
                  <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4'></div>
                  <div className='col-xxl-6 col-xl-8 col-lg-9 col-md-8'>
                    {/* <h6 className='mb-0'>{` Name: ${''}  `}</h6> */}

                    <div className='row'>
                      <div className='col-6'>
                        <h6>Billing Address </h6>
                        <>
                          {customerData.displayBillingAddress ? (
                            <NewLine>{customerData?.displayBillingAddress}</NewLine>
                          ) : (
                            <p>No Billing details are available</p>
                          )}
                        </>
                      </div>
                      <div className='col-6'>
                        <h6>Shipping Address </h6>

                        <>
                          <Field
                            className='form-select-solid'
                            options={shippingAddressOptions}
                            {...formik.getFieldProps('shippingAddressId')}
                            component={CustomSelect}
                            name='shippingAddressId'
                            selectedValue={formik.values.shippingAddressId}
                            onChange={(selected) =>
                              formik.setFieldValue('shippingAddressId', selected?.value || null)
                            }
                            formatOptionLabel={({label}) => (
                              <div style={{whiteSpace: 'pre-line'}}>{label}</div>
                            )}
                          />
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className='row mt-5 mb-0'>
                <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4'>
                  <label className='form-label required'>Sale Order Number</label>
                </div>
                <div className='col-xxl-3 col-xl-3 col-lg-9 col-md-8'>
                  {checkSaleID ? (
                    <Field
                      className='form-control'
                      disabled={true}
                      value={saleOrderDetail?.orderNumber}
                    ></Field>
                  ) : (
                    <Field
                      className='form-select-solid'
                      options={saleOrderList}
                      component={CustomSelect}
                      name={'saleOrderId'}
                      onDropDownChange={(newValue: any) => {
                        onSaleOrderChange(newValue)
                        formik.setFieldValue('saleOrderId', newValue?.value)
                      }}
                      isClearable={true}
                      isMulti={false}
                    ></Field>
                  )}
                  <div className='text-muted fs-7'>Select the SaleOrder.</div>
                </div>
              </div>

              <div className='row mt-5 align-items-center'>
                <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4'>
                  <label className='form-label'>Invoice Date</label>
                </div>
                <div className='col-xxl-3 col-xl-3 col-lg-9 col-md-8 mb-5 mb-xl-0'>
                  <div className='w-100 p-calendar-wraper'>
                    <CalendarInput
                      name='invoiceDate'
                      value={
                        formik.values.invoiceDate && !isNaN(Date.parse(formik.values.invoiceDate))
                          ? new Date(formik.values.invoiceDate)
                          : null
                      }
                      setFieldValue={formik.setFieldValue}
                      placeholder='Invoice Date'
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='col-xxl-1 col-xl-1 col-lg-3 col-md-4 text-xl-end'>
                  <label className='form-label'>Payment Terms</label>
                </div>

                <div className='col-xxl-2 col-xl-2 col-lg-9 col-md-8 mb-5 mb-xl-0'>
                  <Field
                    className='form-select-solid'
                    isLoading={isTermListLoading}
                    options={termOptions}
                    component={CustomSelect}
                    name={'termId'}
                  ></Field>
                </div>

                <div className='col-xxl-1 col-xl-1 col-lg-3 col-md-4 text-xl-end'>
                  <label className='form-label'>Due Date</label>
                </div>
                <div className='col-xxl-2 col-xl-3 col-lg-9 col-md-8'>
                  <div>
                    <CalendarInput
                      name='dueDate' // Field name for "dueDate"
                      value={
                        formik.values.dueDate && !isNaN(Date.parse(formik.values.dueDate))
                          ? new Date(formik.values.dueDate)
                          : null
                      }
                      setFieldValue={formik.setFieldValue}
                      placeholder='Due Date'
                      className='form-control'
                    />
                  </div>
                </div>
              </div>

              <div className='row mt-5 align-items-center'>
                <div className='col-xl-2 col-lg-3 col-md-4'>
                  <label className='form-label'>Place Of Supply</label>
                </div>

                <div className='col-xl-3 col-lg-9 col-md-8 mb-5 mb-xl-0'>
                  <Field
                    className='form-select-solid'
                    options={placeOfSupplyOptions}
                    component={CustomSelect}
                    name={'placeOfSupplyId'}
                  ></Field>
                </div>
              </div>
              <div className='row align-items-center mt-0 mt-xl-5'>
                <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-4'>
                  <label className='form-label'>Discount</label>
                </div>

                <div className='col-xxl-3 col-xl-3 col-lg-9 col-md-8'>
                  <Field
                    type='number'
                    min='1'
                    max='100'
                    className={clsx('form-control')}
                    {...formik.getFieldProps(`discountPercent`)}
                    onChange={(e: any) => {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        formik.setFieldValue('discountPercent', e.target.value)
                        handleDiscountChange(e.target.value as number)
                      } else {
                        formik.setFieldValue('discountPercent', 0)
                        handleDiscountChange(0)
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <div className='card-header'>
              <div className='card-title'>
                <h3>Item Details</h3>
              </div>
            </div>
            <div className='card-body'>
              <ProductSearchbar onSelect={onProductsChange} />
            </div>
            <div className='separator mb-8'></div>
            <ProductSelection onSelect={onProductsChange} />

            <div className='card-body pt-0'>
              <DataTable
                value={saleOrderItems}
                dataKey='id'
                tableClassName='border'
                tableStyle={{minWidth: '50rem'}}
                stripedRows
              >
                <Column field='productName' header='Product' style={{width: '40%'}}></Column>
                <Column
                  field='quantity'
                  header='Quantity'
                  style={{width: '10%'}}
                  body={quantityBody}
                ></Column>
                <Column
                  field='productPrice'
                  header='Unit Price'
                  style={{width: '10%'}}
                  body={unitPriceBody}
                ></Column>
                <Column
                  field='discountAmount'
                  header='Discount'
                  style={{width: '10%'}}
                  body={(rowData: SalesOrderItems) => formatCurrency(rowData.discountAmount)}
                ></Column>
                <Column
                  field='taxPercent'
                  header='Tax'
                  style={{width: '10%'}}
                  body={(rowData: SalesOrderItems) => formatPercentage(rowData.taxPercent)}
                ></Column>
                <Column
                  field='subTotal'
                  header='Sub Total'
                  style={{width: '10%'}}
                  body={(rowData: SalesOrderItems) => formatCurrency(rowData.subTotal)}
                ></Column>
                <Column field='' header='Action' style={{width: '10%'}} body={actionBody}></Column>
              </DataTable>

              <div className='separator mb-7'></div>
              <div className='row mt-4'>
                <div className='col-xl-6 col-lg-12 order-xl-2 col-md-12 mb-3'>
                  <div className='px-5 py-2 bg-light text-dark border'>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                      <label className='fs-4 mt-3'>Sub Total</label>
                      <span className='fs-4 mt-3 '>{formatCurrency(subTotal)}</span>
                    </div>
                    <div className='separator mb-2'></div>

                    <OtherChargesTable
                      otherCharges={otherCharges}
                      setOtherCharges={setOtherCharges}
                    />
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                      <label className='fs-4 mt-3'>Discount</label>
                      <span className='fs-4 mt-3 '>{formatCurrency(totalDiscount)}</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                      <label className='fs-4 mt-3'>Tax Amount</label>
                      <span className='fs-4 mt-3 '>{formatCurrency(totalTaxAmount)}</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                      <label className='fs-4 mt-3'>Round Off</label>
                      <span className='fs-4 mt-3 '>{formatCurrency(roundOff)}</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                      <label className='fs-4 mt-3'>Grand Total</label>
                      <span className='fs-4 mt-3 '>{formatCurrency(grandTotal)}</span>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-12 order-xl-1 col-md-12 mb-3'>
                  <label className='fw-bold fs-6 mb-2 ms-1'>Notes</label>

                  <textarea
                    rows={3}
                    placeholder='Note'
                    {...formik.getFieldProps('notes')}
                    name='notes'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1',
                      {'is-invalid': formik.touched.notes && formik.errors.notes},
                      {
                        'is-valid': formik.touched.notes && !formik.errors.notes,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.notes && formik.errors.notes && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.notes}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className='row pb-5 mb-5 border-bottom'>
                  <div className='col-2 col-md-2 text-gray-400 fs-5 fw-semibold'>
                    {' '}
                    <label htmlFor='kt_create_products_form_products_productname'>Product </label>
                  </div>
                  <div className='col-2 col-md-2 text-gray-400 fs-5 fw-semibold'>
                    {' '}
                    <label htmlFor='kt_create_products_form_products_quantity'>Quantity </label>
                  </div>
                  <div className='col-2 col-md-2 text-gray-400 fs-5 fw-semibold'>
                    {' '}
                    <label htmlFor='kt_create_products_form_products_unitprice'>Unit Price </label>
                  </div>
                  <div className='col-2 col-md-2 text-gray-400 fs-5 fw-semibold'>
                    {' '}
                    <label htmlFor='kt_create_products_form_products_discountpercent'>
                      Discount Percent ( % ){' '}
                    </label>
                  </div>
                  <div className='col-2 col-md-2 text-gray-400 fs-5 fw-semibold'>
                    {' '}
                    <label htmlFor='kt_create_products_form_products_taxpercent'>
                      Tax Percent ( % ){' '}
                    </label>
                  </div>
                  <div className='col-1 col-md-1 text-gray-400 fs-5 fw-semibold'>
                    {' '}
                    <label htmlFor='kt_create_products_form_products_total'> Total ( ₹ ) </label>
                  </div>
                  <div className='col-1 col-md-1 text-center text-gray-400 fs-5 fw-semibold'>
                    {' '}
                    <label htmlFor='kt_create_products_form_products_actions'>Actions </label>
                  </div>
                </div> */}

            {/* <div className='separator mb-7'></div> */}

            <div className='card-footer text-end'>
              <button
                type='reset'
                className='btn btn-light me-3 border'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                disabled={formik.isSubmitting}
              >
                Discard
              </button>
              <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h2>Confirmation</h2>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body'>
                      <div className='w-100'>
                        {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
                            <i className="las la-exclamation-circle"></i>
                          </div> */}

                        <div className='fv-row text-center'>
                          <h5>Are you sure you want to discard ?</h5>
                        </div>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light'
                        data-bs-dismiss='modal'
                        onClick={() => navigate(-1)}
                      >
                        Yes
                      </button>
                      <button type='button' className='btn btn-primary' data-bs-dismiss='modal'>
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-color-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Submit</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>

      {formik.isSubmitting && <Loading />}
    </FormikProvider>
  )
}

export {InvoiceAddPage}

{
  /* <div className="card-body pt-0">
                      <div className='row mb-4'>
                        <label className='form-label'></label>
                        <div className='form-check form-check-custom form-check-solid ms-1'>
                          <input
                            className='form-check-input ms-1'
                            type='checkbox'
                            checked={paymentReceived}
                            onChange={paymentReceive}

                          /><span className='ms-4'>I have received the payment</span>
                        </div>
                      </div>
                      {paymentReceived ?
                        <div className='row me-4 mb-8'>
                          <div className='col-6 col-md-6'>
                            <label className='form-label required'>Payment Mode</label>
                            <Field
                              className="form-select-solid"
                              // options={tenantType}
                              component={CustomSelect}

                            ></Field>
                          </div>
                          <div className='col-6 col-md-6'>
                            <label className='form-label required'>Deposit To</label>
                            <Field
                              className="form-select-solid"
                              // options={bankAccount}
                              component={CustomSelect}

                            ></Field>
                          </div>
                        </div>
                        : ''}
                    </div> */
}
