import React, { FC, useState } from 'react'
import { Result } from '../../../../core/models/Result';
import { KTSVG, PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { camelize } from '../../../../core/utils/StringHelpers';
import clsx from 'clsx';
import CustomAsyncSelect from '../../../../core/shared/components/CustomAsyncSelect';
import { ProductGroup } from '../../../catalog/productGroup/productgroup-list/core/_models';
import { getProductGroupList } from '../../../catalog/productGroup/productgroup-list/core/_requests';
import { NewArrival, initialNewArrival } from '../core/_models';
import * as Yup from "yup";
import { FileUploadRequest } from '../../../../core/models/FileUploadRequest';
import { updateNewArrival } from '../core/_requests';
import UploadImage from '../../../../core/shared/components/UploadImage';


type Props = {
    isNewArrivalLoading: boolean;
    data: Result | NewArrival;
};


const newArrivalSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[A-Za-z0-9\-_', .]*$/, "Only alphanumeric, _, -, ' and space are allowed for this field")
        .required("Please enter First name"),
    productGroupIds: Yup.array().required('Please select product group')
})
const NewArrivalEditPage: FC<Props> = ({ data, isNewArrivalLoading }) => {
    const [selectedImage, setSelectedImage] = useState<FileUploadRequest>({})


    const navigate = useNavigate()

    const newArrivalData = data as NewArrival
    const initialValues = {
        id: newArrivalData.id,
        name: newArrivalData.name,
        imagePath: newArrivalData.imagePath,
        newArrivalImagePath: '',
        productGroupIds: newArrivalData.newArrivalProductGroups?.map((item) => item.productGroupId),
        isActive: newArrivalData.isActive,
        statusId: (newArrivalData.isActive ? 1 : 0) || initialNewArrival.statusId
    }

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: initialValues,
        validationSchema: newArrivalSchema,

        onSubmit: async (formValues, { setSubmitting, setFieldError }) => {

            setSubmitting(true)
            let newArrivalData: NewArrival = {
                id: formValues.id,
                name: formValues.name,
                productGroupIds: formValues.productGroupIds as number[],

            }

            if (isNotEmpty(selectedImage.data)) {

                newArrivalData.uploadImage = selectedImage;


            }

            try {
                let result: Result;
                if (isNotEmpty(newArrivalData.id)) {
                    newArrivalData.isActive = formValues.statusId === 1

                    result = await updateNewArrival(newArrivalData)
                    if (result.hasOwnProperty('succeeded') && result?.succeeded) {
                        setSubmitting(true)
                        toast.success("New Arrival updated successfully.")
                        navigate('/cms/newarrival/list')

                    }
                    else {
                        if (result.statusCode === 400) {
                            result.propertyResults.map((error) =>
                                setFieldError(camelize(error.propertyName), error.errorMessage),
                                toast.error(result.exception)

                            )
                        }
                    }
                }

            } catch (ex) {
                console.error(ex)
                toast.error("Error caught during adding newarrival.")
            }
        },
    })


    return (
        <FormikProvider value={formik}>
            <form
                id='banner_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate

            >
                <div className="card mb-5 mb-xl-5">
                    <div className="card-header">
                        <div className="card-title">
                            <h3>New Arrivals</h3>
                        </div>
                    </div>


                    <div className="card-body">
                        <div className="row mb-lg-5">

                            <label className="col-xl-2 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                                New Arrival Banner Image
                            </label>

                            <div className="col-lg-4">
                                <input type="hidden" {...formik.getFieldProps('newArrivalImagePath')}></input>

                                <UploadImage
                                    ImagePath={`${newArrivalData.imagePath}`}
                                    HandleUpload={(img: FileUploadRequest) => {
                                        setSelectedImage(img);
                                        formik.setFieldValue('newArrivalImagePath', img.name);

                                    }}
                                    HandleRemove={() => { setSelectedImage({}) }} />


                                {formik.touched.newArrivalImagePath && formik.errors.newArrivalImagePath && (
                                    <div className='fv-help-block text-center'>
                                        <span role='alert ' className="text-danger">{formik.errors.newArrivalImagePath}</span>
                                    </div>
                                )}
                            </div>


                        </div>
                        <div className="row mb-lg-5">
                            <label className="col-xl-2 col-lg-3 col-md-3 col-form-label required fw-semibold fs-6">
                                New Arrival Name
                            </label>

                            <div className="col-lg-8">
                                <Field
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-lg-0',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                        {
                                            'is-valid': formik.touched.name && !formik.errors.name,
                                        }
                                    )}
                                    placeholder='Name'
                                    {...formik.getFieldProps('name')}
                                    name='name'
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.name}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />


                            </div>

                        </div>
                        <div className="row mb-lg-5">
                            <label className="col-xl-2 col-lg-3 col-md-3 col-form-label required fw-semibold fs-6">
                                Product Group
                            </label>
                            <div className="col-lg-8">
                                <Field
                                    className='form-select-solid'
                                    component={CustomAsyncSelect<ProductGroup>}
                                    {...formik.getFieldProps(`productGroupIds`)}
                                    name={`productGroupIds`}
                                    loadOptionFunc={getProductGroupList}
                                    searchByField="name"
                                    labelField="name"
                                    isMulti={true}
                                    disabledisActiveFilter={true}
                                >
                                </Field>
                                {formik.touched.productGroupIds && formik.errors.productGroupIds && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.productGroupIds}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-end">
                        <button
                            type='reset'
                            className='btn btn-light me-3'
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_1"
                            disabled={formik.isSubmitting}
                        >
                            Discard
                        </button>
                        <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h2>Confirmation</h2>
                                        <div
                                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <KTSVG
                                                path="/media/icons/duotune/arrows/arr061.svg"
                                                className="svg-icon svg-icon-2x"
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        <div className="w-100">
                                            <div className="fv-row text-center">
                                                <h5>Are you sure you want to discard ?</h5>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            data-bs-dismiss="modal"
                                            onClick={() => navigate('/cms/newarrival/list ')}
                                        >
                                            Yes
                                        </button>
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-color-modal-action='submit'
                            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                        >
                            <span className='indicator-label'>Submit</span>
                            {(formik.isSubmitting) && (
                                <span className='indicator-progress'>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>

            </form>
        </FormikProvider>
    )
}

export default NewArrivalEditPage