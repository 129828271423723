import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"
import { PaymentMode, PaymentModeQueryResponse } from './_models'
import { Result } from '../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const PaymentMode_LIST_URL = `${API_URL}/paymentmode/search`
const PaymentMode_BY_ID_URL = `${API_URL}/paymentmode`
const PaymentMode_ADD_URL = `${API_URL}/paymentmode`

const getPaymentModeList = async (filter: PaginationFilter): Promise<PaymentModeQueryResponse> => {
  return await axios
    .post(`${PaymentMode_LIST_URL}`, filter)
    .then((d: AxiosResponse<PaymentModeQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getPaymentModeById = async (id: ID): Promise<Result | PaymentMode> => {
  return axios
    .get(`${PaymentMode_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<PaymentMode>) => response.data)
    .then((response: PaymentMode) => response)
    .catch((err: Result) => {
      return err
    })
}

const createPaymentMode = async (brand: PaymentMode): Promise<Result> => {
  return await axios
    .post(PaymentMode_ADD_URL, {
      name: brand.name?.trim(),
      
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updatePaymentMode = (brand: PaymentMode): Promise<Result> => {
  return axios
    .put(`${PaymentMode_BY_ID_URL}/${brand.id}`, {
      id: brand.id,
      name: brand.name?.trim(),      
      isActive: brand.isActive

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
export {
  getPaymentModeList,
  getPaymentModeById,
  createPaymentMode,
  updatePaymentMode

}
