import { SortOrder } from 'primereact/datatable';
import { Dispatch, SetStateAction } from 'react'

export type ID = undefined | null | number | string
export const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) =>
  obj[key];

export interface IDto {

}
export type DefaultParams = {
  id?: ID
  name?: string,
}

export type Search = {
  keyword?: string | undefined
  fields?: Array<string> | undefined
}

export type Filter = {
  logic?: string | undefined
  filters?: Array<Filter> | undefined
  field?: string | undefined
  operator?: string | undefined
  value?: any | undefined
}

export type BaseFilter = {
  keyword?: string | undefined;
  advancedSearch?: Search | undefined;
  advancedFilter?: Filter | undefined;
};
export type StatusFilter = BaseFilter &{
  isActive?: boolean;
}
export type PaginationFilter = BaseFilter & SortState & DataTableSort & {
  first?: number;
  tenantTypeId?: number
  pageNumber?: number;
  pageSize?: number;
  orderBy?: Array<string>;
};

export type DataTableSort = {
  sortField?: string,
  sortOrder?: SortOrder,
}

export type PaginationResponse = {
  currentPage?: number,
  totalPages?: number,
  totalCount?: number,
  pageSize?: number,
  hasPreviousPage?: boolean,
  hasNextPage?: boolean
  first?: number
}

export type PaginationState = {
  first:number,
  page: number
  items_per_page: 10 | 30 | 50 | 100
  links?: Array<{ label: string; active: boolean; url: string | null; page: number | null; totalPages: number | null }>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
  orderBy?: string[];
}

export type FilterState = {
  filter?: string | unknown
}

export type SearchState = {
  search?: string
}

export type PaginationRange = {
  start: number
  end: number
}

export type Response<T> = {
  data?: T
  pagination?: PaginationResponse
}

export type QueryState = PaginationFilter & SortState & FilterState  & StatusFilter// PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  pageNumber: 1,
  pageSize: 10,
  order: 'asc',
  orderBy: [],
  first: 0
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {
    
   },
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }
export type ListViewContextProps = {
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
}

export const initialListView: ListViewContextProps = {
  setItemIdForUpdate: () => { },
}

export type StatusViewContextProps = {
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  actionType?: String
  setActionType: Dispatch<SetStateAction<String>>
}
export const initialStatusView: StatusViewContextProps = {
  setItemIdForUpdate: () => { },
  setActionType: () => { }
}


export const pageSizeOption = [10, 20, 50, 100];
export const defaultPageSize = 20