import {Route, Routes, Outlet} from 'react-router-dom'
import {
  MustHavePermission,
  SWSection,
  SWResource,
  SWAction,
} from '../../../core/shared/core/permissionUtils'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import ExpenseList from './pages/ExpenseList'
import UnAuthorized from '../../errors/components/UnAuthorized'
import ExpensesAddPage from './pages/ExpensesAddPage'
import ExpensesEditPage from './pages/ExpensesEditPage'
import ExpenseDetails from './pages/ExpenseDetails'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ExpensePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Expense </PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Expenses, SWAction.Search) ? (
                <ExpenseList />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />

        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}> Add Expense</PageTitle>
              <ExpensesAddPage />
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}> Edit Expense</PageTitle>
              <ExpensesEditPage />
            </>
          }
        />
        <Route
          path='details/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Expense Details</PageTitle>
              <ExpenseDetails />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ExpensePage
