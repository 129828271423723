import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {InvoiceAddPage} from './pages/InvoiceAddPage'
// import {InvoiceEditPage} from './pages/InvoiceEditPage'
// import {InvoiceDetailPage} from './pages/InvoiceDetailPage'
import {
  MustHavePermission,
  SWSection,
  SWResource,
  SWAction,
} from '../../../core/shared/core/permissionUtils'
import UnAuthorized from '../../errors/components/UnAuthorized'
import {InvoiceList} from './pages/InvoiceList'
import InvoiceDetailsPage from './pages/InvoiceDetailsPage'
import InvoiceEditPage from './pages/InvoiceEditPage'
const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const InvoicePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Invoice Page</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Search) ? (
                <InvoiceList />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Edit Invoice</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Update) ? (
                <InvoiceEditPage />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}> Invoice Detail</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.View) ? (
                <InvoiceDetailsPage />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />

        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Invoice</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Create) ? (
                <InvoiceAddPage />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />

        <Route
          path='add/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Invoice</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Create) ? (
                <InvoiceAddPage />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default InvoicePage
