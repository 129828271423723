import React, {useState} from 'react'
import {KTCard, KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import {useQuery} from 'react-query'
import {getPaymentMadeList} from '../_request'
import {Loading} from '../../../../core/shared/components/Loading'
import {
  MustHavePermission,
  SWAction,
  SWResource,
  SWSection,
} from '../../../../core/shared/core/permissionUtils'
import {useNavigate} from 'react-router-dom'
import {PMActionsCell} from '../components/pmtable/PMActionsCell'
import PMAdvanceSearch from '../components/pmtable/PMAdvanceSearch'
import {useImmer} from 'use-immer'
import {InputText} from 'primereact/inputtext'

const PaymentMadeList = () => {
  const navigate = useNavigate()
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [filterQuery, setFilterQuery] = useState({})
  const [isDeletingPaymentMade, setIsDeletingPaymentMade] = useState(false)
  const [paginationFilters, setPaginationFilters] = useImmer({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [] as string[],
    order: '' as 'asc' | 'desc',
    first: 0,
    sortField: '',
    sortOrder: 0,
  })

  const handleFilterQueryChange = (newFilterQuery: any) => {
    setFilterQuery(newFilterQuery)
  }

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['getPaymentMadeRecords', paginationFilters, filterQuery],
    async () => {
      return await getPaymentMadeList({...filterQuery, ...paginationFilters})
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const onSort = (event: any) => {
    console.log(event)
    setPaginationFilters((draft) => {
      draft.pageNumber = event.page === undefined ? 1 : (event.page as number) + 1
      draft.pageSize = event.rows
      draft.first = event.first
      draft.sortField = event.sortField
      event.sortField && (draft.order = event.sortOrder === 1 ? 'asc' : 'desc')
      event.sortField &&
        (draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`])
    })
  }

  const renderHeader = () => (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          placeholder='Keyword Search'
          value={globalFilterValue}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
        />
      </span>
    </div>
  )

  const header = renderHeader()

  const filteredData = response?.data?.filter((item) =>
    Object.values(item).some((value: any) => {
      return (
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    })
  )
  const leftContent = (
    <span className='mx-3' style={{color: 'var(--text-color)', userSelect: 'none'}}>
      {`Showing ${(response?.pagination?.totalCount as number) > 0 ? (paginationFilters.first as number) + 1 : 0} to 
            ${Math.min(
              (response?.pagination?.currentPage as number) *
                (response?.pagination?.pageSize as number),
              response?.pagination?.totalCount as number
            )} 
            out of ${response?.pagination?.totalCount} Records`}
    </span>
  )

  return (
    <>
      <PMAdvanceSearch refetch={refetch} onFilterQueryChange={handleFilterQueryChange} />
      <KTCard>
        <div className='card-header justify-content-bewteen align-items-center'>
          <div className='card-title'>
            <h3>Payment Made</h3>
          </div>
          <div className='d-flex justify-content-end align-items-center gap-2'>
            {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.Create) ? (
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => navigate('/purchase/payment-made/add')}
              >
                <KTIcon iconName='plus' className='fs-2' />
                Add
              </button>
            ) : null}
          </div>
        </div>
        <KTCardBody className='py-4'>
          <DataTable
            value={filteredData}
            stripedRows
            header={header}
            showGridlines
            filterDisplay='menu'
            tableClassName='table table-bordered table-hover mb-0'
            tableStyle={{minWidth: '50rem'}}
            loading={isLoading}
            sortMode='single'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
            paginatorClassName='dataTables_paginate paging_bootstrap_full_number mt-0'
            paginator
            paginatorLeft={leftContent}
            onSort={onSort}
            onPage={onSort}
            rows={paginationFilters.pageSize}
            totalRecords={response?.pagination?.totalCount}
            emptyMessage='No Payment Made Found.'
            rowsPerPageOptions={[10, 25, 50, 100]}
            sortField={paginationFilters.orderBy[0]?.split(' ')[0]}
            sortOrder={paginationFilters.orderBy[0]?.split(' ')[1] === 'asc' ? 1 : -1}
            lazy
          >
            <Column
              field={'paymentNumber'}
              header={'Payment Number'}
              sortable={true}
              sortField='paymentNumber'
            />
            <Column
              field={'vendorName'}
              header={'Vendor Name'}
              sortable={true}
              sortField='vendor.printName'
            />
            <Column
              field={'paymentDate'}
              header={'Payment Date'}
              body={(rowData) => formatDate(rowData?.paymentDate, 'dd MMM yyyy')}
              sortable={true}
              sortField='paymentDate'
            />
            <Column
              field={'paymentModeName'}
              header={'Payment Mode'}
              sortable={true}
              sortField='paymentMode.name'
            />
            <Column
              field={'amountPaid'}
              header={'Amount Paid'}
              body={(rowData) => formatCurrency(rowData?.amountPaid)}
              sortable={true}
              sortField='amountPaid'
            />
            <Column
              field={'actions'}
              header={'Actions'}
              body={(rowData) => (
                <PMActionsCell
                  id={rowData.id}
                  status={rowData.paymentStatusId}
                  onLoadingChange={setIsDeletingPaymentMade}
                />
              )}
            />
          </DataTable>

          {/* <div className='d-flex justify-content-between align-items-center flex-wrap px-4 py-3 mx-auto gap-6'>
              <div className='d-flex align-items-center'>
                <button
                  className='btn btn-light btn-sm me-2'
                  onClick={() => {
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                    setPaginationFilters((draft) => {
                      draft.pageNumber = Math.max(currentPage - 1, 1)
                    })
                  }}
                  disabled={currentPage === 1}
                >
                  <i className='bi bi-chevron-double-left'></i>
                </button>
                {Array.from({length: response?.pagination?.totalPages || 0}, (_, i) => i + 1).map(
                  (pageNum) => (
                    <button
                      key={pageNum}
                      className={`btn btn-sm mx-1 ${
                        pageNum === currentPage ? 'btn-primary' : 'btn-light'
                      }`}
                      onClick={() => {
                        setCurrentPage(pageNum)
                        setPaginationFilters((draft) => {
                          draft.pageNumber = pageNum
                        })
                      }}
                    >
                      {pageNum}
                    </button>
                  )
                )}
                <button
                  className='btn btn-light btn-sm ms-2'
                  onClick={() => {
                    setCurrentPage((prev) => Math.min(prev + 1, response?.pagination?.totalPages))
                    setPaginationFilters((draft) => {
                      draft.pageNumber = Math.min(currentPage + 1, response?.pagination?.totalPages)
                    })
                  }}
                  disabled={currentPage === response?.pagination?.totalPages}
                >
                  <i className='bi bi-chevron-double-right'></i>
                </button>
              </div>
              <div className='d-flex align-items-center me-3'>
                <select
                  className='form-select form-select-sm'
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value))
                    setCurrentPage(1)
                    setPaginationFilters((draft) => {
                      draft.pageNumber = 1
                      draft.pageSize = Number(e.target.value)
                    })
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div> */}
          {isLoading && <Loading />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default PaymentMadeList
