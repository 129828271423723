import axios, {AxiosResponse} from 'axios'

import {PaginationFilter} from '../bills/_models'
import {
  AddPaymentMadePayload,
  AddPaymentMadeQueryResponse,
  BankQueryResponse,
  BanksList,
  BillDetails,
  PaymentMadeDetailsQueryResponse,
  PaymentMadeQueryResponse,
  PaymentModeQueryResponse,
  VendorQueryResponse,
} from './_models'
import {FileResult, Result} from '../../../core/models/Result'
import {ID} from '../../../../_metronic/helpers'
import {toast} from 'react-toastify'

const API_URL = process.env.REACT_APP_STORE_API_URL
const PAYMENT_MADE_LIST_URL = `${API_URL}/paymentmade/search`
const PAYMENT_MADE_DETAILS_URL = `${API_URL}/paymentmade`
const PAYMENT_MODE_ADD_URL = `${API_URL}/paymentmode/search`
const Vendor_LIST_URL = `${API_URL}/vendors/search`
const Bank_List_URL = `${API_URL}/bank/search`
const PAYMENT_MADE_DOWNLOAD = `${API_URL}/paymentmade/downloadpdf`
const Bill_DETAILS = `${API_URL}/bills`

const addPaymentMade = async (payload: AddPaymentMadePayload) => {
  return axios
    .post(`${PAYMENT_MADE_DETAILS_URL}`, payload)
    .then((d: AxiosResponse<AddPaymentMadeQueryResponse>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const downloadPaymentMade = async (id: number): Promise<any | FileResult> => {
  return await axios
    .get(`${PAYMENT_MADE_DOWNLOAD}/${id}`, {
      responseType: 'blob',
    })
    .then(async (response: any) => {
      if (response.type == 'application/json') {
        const jsonData = await response.text()
        const errorData = JSON.parse(jsonData)
        return errorData
      } else {
        let filename = response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          .replace('filename=', '')
          .trim()

        var result: FileResult = {
          data: response.data,
          name: filename,
        }
        return result
      }
    })
    .catch((err: Result) => {
      toast.error(err.exception)
      return err
    })
}

const getPaymentMadeList = async (filter: PaginationFilter) => {
  return axios
    .post(`${PAYMENT_MADE_LIST_URL}`, filter)
    .then((d: AxiosResponse<PaymentMadeQueryResponse>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getPaymentModeList = async () => {
  return await axios
    .post(`${PAYMENT_MODE_ADD_URL}`, {})
    .then((d: AxiosResponse<PaymentModeQueryResponse>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getPaymentMadeDetails = async (id: number) => {
  return axios
    .get(`${PAYMENT_MADE_DETAILS_URL}/${id}`)
    .then((d: AxiosResponse<PaymentMadeDetailsQueryResponse>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getVendorList = async (filter: PaginationFilter) => {
  return await axios
    .post(`${Vendor_LIST_URL}`, filter)
    .then((d: AxiosResponse<VendorQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getBankList = async (filter: PaginationFilter): Promise<BankQueryResponse> => {
  return await axios
    .post(`${Bank_List_URL}`, filter)
    .then((d: AxiosResponse<BankQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getBillDetails = async (id: ID): Promise<BillDetails> => {
  return await axios
    .get(`${Bill_DETAILS}/${id}`)
    .then((d) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const deletePaymentMade = async (id: ID) => {
  return await axios
    .delete(`${PAYMENT_MADE_DETAILS_URL}/${id}`)
    .then((d) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

export {
  getPaymentMadeList,
  getPaymentMadeDetails,
  getPaymentModeList,
  getVendorList,
  getBankList,
  addPaymentMade,
  getBillDetails,
  downloadPaymentMade,
  deletePaymentMade,
}
