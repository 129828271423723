import {FC} from 'react'
import ActionDropdown from '../../../../core/shared/components/ActionDropdown'
import {InvoiceDto} from '../core/_models'
import {DropdownItem} from '../../../../core/models/DropdownItem'

interface InvoiceActionProps {
  id: number
  rowData: InvoiceDto
  showCancelModal: (orderId: number) => void
}

const InvoiceAction: FC<InvoiceActionProps> = ({id, rowData, showCancelModal}) => {
  const actionLinks: DropdownItem[] = []

  if (rowData.invoiceStatusId !== 3) {
    actionLinks.push({id: 1, label: `Edit Invoice`, href: `/sales/invoice/edit/${id}`})
  }

  actionLinks.push({id: 2, label: `View Details`, href: `/sales/invoice/detail/${id}`})

  if (rowData.invoiceStatusId !== 3) {
    actionLinks.push({
      id: 3,
      label: `Cancel Invoice`,
      href: `#`,
      onClick: () => cancelInvoice(rowData),
    })
  }

  const cancelInvoice = async (rowData: InvoiceDto) => {
    showCancelModal(rowData.id)
  }

  return <ActionDropdown buttonLabel='Actions' items={actionLinks} />
}

export default InvoiceAction
