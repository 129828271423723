import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'
import { ExpenseCategory, StockFilter } from './core/_model'
import { getExpenseCategoryReport } from './core/_request'
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";
import { useNavigate } from 'react-router-dom'

function ExpenseCategoryPage() {
  const [salesStock, setSalesStock] = useState<ExpenseCategory[]>([])
  const [filters, setFilters] = useState<StockFilter>({})
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState<Date | string | undefined>(undefined);
  const [filterDateTo, setFilterDateTo] = useState<Date | string | undefined>(undefined);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<ExpenseCategory[]>(salesStock);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getExpenseCategoryReport(filters);
        if (Array.isArray(data)) {
          setSalesStock(data as ExpenseCategory[]);
        } else {
          console.error('Fetched data is not an array:', data);
        }
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };
    fetchData();
  }, [filters]);

  useEffect(() => {
    const newFilteredData = salesStock.filter((item: ExpenseCategory) =>
      Object.values(item).some((value: string | number | Date) =>
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    );
    setFilteredData(newFilteredData);
  }, [salesStock, globalFilterValue]);

  const formatCurrency = (amount: number, locale: string = 'en-IN', currency: string = 'INR'): string => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(amount);
  };

  const handleDateChange = (selectedDates: Date[], dateName: string) => {
    if (dateName === 'startDate') {
      if (selectedDates[0] === undefined) {
        setFilterDateFrom('')
      }
      else {

        setFilterDateFrom(selectedDates[0]?.toDateString());
      }

    } else if (dateName === 'endDate') {
      if (selectedDates[0] === undefined) {
        setFilterDateTo('')
      }
      else {

        setFilterDateTo(selectedDates[0]?.toDateString());
      }
    }

  };


  const updateSearchFilters = () => {
    const filter: StockFilter = {};
    if (filterDateFrom) {
      const startDate = toZonedTime(new Date(filterDateFrom as Date), 'Asia/Kolkata');
      filter.startDate = format(startDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
    }

    if (filterDateTo) {
      const endDate = toZonedTime(new Date(filterDateTo as Date), 'Asia/Kolkata');
      filter.endDate = format(endDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
    }

    // const newFilteredData = salesStock.filter((item: SalesByAgentStock) =>
    //   Object.values(item).some((value: string | number) =>
    //     typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
    //   )
    // );
    // setFilteredData(newFilteredData);
    setFilters(filter);
  };
  const formatDate = (rowData: any, dateFormat: string) => {
    const date = new Date(rowData);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error('Invalid date:', rowData);
      return 'Invalid date';
    }
    return format(date, dateFormat);

  };

  return (
    <>
      <div className="card mb-5">
        <div className="card-header justify-content-between align-items-center">
          <div className="card-title">
            <h3>Expense Category Filter</h3>
          </div>
          <button className="btn"
            onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
            {
              advancedSearchVisible ?
                <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
                :
                <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
            }
          </button>
        </div>
        {advancedSearchVisible && (
          <>
            <div className="card-body">
              {/* <div className="row mb-7">
          <div className="col-lg-6">
            <div className="input-group">
              <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>

              <input
                type="text"
                className="form-control form-control-solid ps-10"
                id="txtsearchText"
                placeholder="Search Expense Category"
                value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)}
              />
              <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}
              >
                Search
              </button>
            </div>
          </div>
          <div className="col-lg-4">
            <button
              className="btn btn-link"
              onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
            >
              Advanced Search
            </button>
          </div>
          <div className='col-lg-2 '>
            <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}>Back

            </button>
          </div>
        </div> */}

              <div className="collapse" id="kt_advanced_search_form">
                <div className="separator separator-dashed mt-9 mb-6"></div>
              </div>



              <div className="col-lg-4">
                <label className="fs-6 form-label fw-bold text-dark">
                  Date
                </label>
                <div className="row g-3">
                  <div className="col">
                    <Flatpickr
                      //  value={dateState.date1}
                      options={{ dateFormat: 'Y-m-d' }}
                      onChange={(selectedDates) => handleDateChange(selectedDates, 'startDate')}
                      className="form-control form-control-solid"
                      placeholder="From"

                    />
                  </div>
                  <div className="col">
                    <Flatpickr
                      // value={dateState.date2}
                      options={{ dateFormat: 'Y-m-d' }}
                      onChange={(selectedDates) => handleDateChange(selectedDates, 'endDate')}
                      className="form-control form-control-solid"
                      placeholder="To"

                    />
                  </div>
                </div>
              </div>




            </div>
            <div className="card-footer text-end">
              <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}
              >
                Search
              </button>
            </div>
          </>
        )}
      </div >
      <KTCard>
        <div className="card-header justify-content-between align-items-center">
          <div className="card-title">
            <h3>Expense Category</h3>
          </div>

        </div>
        <KTCardBody>
          <>
            {/* <div className='card-header border-0 pt-6 '>
              <div className='surface-section px-4 py-5 md:px-6 lg:px-8'>
                <div className='flex flex-column md:align-items-center md:justify-content-between md:flex-row'>
                  <div className='h2'>Sales By Customer</div>
                </div>
              </div>
            </div> */}
            <div>
              <DataTable
                className='table-responsive'
                tableClassName='table border table-striped table-row-bordered gy-5 gs-7'
                paginatorClassName='pagination'
                paginatorLeft
                value={filteredData}
                showGridlines
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'
                paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
              >


                <Column
                  //className='text-start text-muted fw-bolder fs-6   gs-0 min-w-125px'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='expenseCategoryName'
                  header='Expense Category'
                  sortable />
                <Column
                  //className='text-start text-muted fw-bolder fs-6   gs-0 min-w-125px'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='expenseCount'
                  header='Expense Count'
                  sortable />

                <Column
                  //className='text-start text-muted fw-bolder fs-6  gs-0 min-w-125px'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='totalExpenseAmount'
                  header='Expense Amount'
                  sortable
                  body={(rowData) => formatCurrency(rowData.totalExpenseAmount as number)} />



              </DataTable>
            </div>
          </>
        </KTCardBody>
        <div className="card-footer text-end">
          <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}><i className='ki-duotone ki-left fs-2' /> Back </button>
        </div>
      </KTCard></>
  )
}

export default ExpenseCategoryPage
