import {FC, useState} from 'react'
import {
  defaultPageSize,
  KTCard,
  KTCardBody,
  KTIcon,
  pageSizeOption,
  PaginationFilter,
} from '../../../../../_metronic/helpers'
import {DataTable, DataTableStateEvent, SortOrder} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InvoiceDto} from '../core/_models'
import {getInvoiceList, updateInvoiceStatus} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import {FileResult} from '../../../../core/models/Result'
import {saveAs} from 'file-saver'
import {
  MustHavePermission,
  SWSection,
  SWResource,
  SWAction,
} from '../../../../core/shared/core/permissionUtils'
import {useMutation, useQuery} from 'react-query'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import {useImmer} from 'use-immer'
import {InputText} from 'primereact/inputtext'
import clsx from 'clsx'
import {Loading} from '../../../../core/shared/components/Loading'
import {DropdownItem} from '../../../../core/models/DropdownItem'
import ActionDropdown from '../../../../core/shared/components/ActionDropdown'
import {Modal} from 'react-bootstrap'
import InvoiceAction from '../components/InvoiceAction'
import {toast} from 'react-toastify'

const InvoiceList = () => {
  const navigate = useNavigate()
  const [isExpand, setExpand] = useState<boolean>(false)
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [action, setAction] = useState('')
  const [note, setNote] = useState('')
  const [selectedSaleOrderId, setSelectedSaleOrderId] = useState<number>()
  const [showCancelModal, setShowCancelModal] = useState<boolean>()
  const [paginationModel, setPaginationModel] = useImmer<PaginationFilter>({
    first: 0,
    pageSize: defaultPageSize,
    sortField: 'invoiceDate',
    sortOrder: -1,
    orderBy: ['invoiceDate desc'],
  })

  const {
    data: invoiceResponse,
    isLoading,
    refetch,
  } = useQuery(['invoice', paginationModel], () => {
    return getInvoiceList({...paginationModel})
  })

  const leftContent = (
    <span className='mx-3' style={{color: 'var(--text-color)', userSelect: 'none'}}>
      {`Showing ${(invoiceResponse?.pagination?.totalCount as number) > 0 ? (paginationModel.first as number) + 1 : 0} to 
        ${Math.min(
          (invoiceResponse?.pagination?.currentPage as number) *
            (invoiceResponse?.pagination?.pageSize as number),
          invoiceResponse?.pagination?.totalCount as number
        )} 
        out of ${invoiceResponse?.pagination?.totalCount} Records`}
    </span>
  )

  const handleClose = () => {
    setSelectedSaleOrderId(undefined)
    setAction('')
    setShowCancelModal(false)
  }

  const onPageOrSortChange = (event: DataTableStateEvent) => {
    setPaginationModel((draft) => {
      draft.pageNumber = event.page === undefined ? 1 : (event.page as number) + 1
      draft.pageSize = event.rows
      draft.first = event.first
      draft.sortField = event.sortField
      draft.sortOrder = event.sortOrder as SortOrder
      draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`]
    })
  }

  const renderHeader = () => (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          placeholder='Keyword Search'
          value={globalFilterValue}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
        />
      </span>
    </div>
  )

  const header = renderHeader()

  const filteredData = invoiceResponse?.data?.filter((item: InvoiceDto) =>
    Object.values(item).some((value: any) => {
      return (
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    })
  )

  const {mutate: cancelInvoiceMutation, isLoading: cancelInvoiceLoading} = useMutation({
    mutationKey: ['cancelPurchaseOrder'],
    mutationFn: async (selectedSaleOrderId: number) =>
      await updateInvoiceStatus(selectedSaleOrderId, note),
    onSuccess: () => {
      toast.success('Invoice cancelled successfully')
      handleClose()
      refetch()
    },
    onError: () => {
      toast.error('Failed to cancel invoice')
    },
  })

  const handleConfirm = () => {
    if (selectedSaleOrderId) {
      cancelInvoiceMutation(selectedSaleOrderId)
    }
  }

  return (
    <>
      <KTCard className='mb-5'>
        <div className='card-header'>
          <div className='card-title justify-content-between w-100'>
            <h3>Invoice Filter</h3>
            <button className='btn' onClick={() => setExpand(!isExpand)}>
              <img
                src={isExpand ? '/media/icons/up-angle.svg' : '/media/icons/down-angle.svg'}
                alt='Filter'
                height={25}
                width={25}
              />
            </button>
          </div>
        </div>
        <div className={clsx('card-body', {hide: !isExpand})}>
          <div className='row g-5'>
            <div className='col-lg-4'>
              <label className='fs-6 form-label fw-bold text-dark'>Invoice Number</label>

              {/* <input type="text" className="form-control" placeholder="Enter Order Number" autoComplete='off' name="orderNumber"
                                value={filterOrderNumber} onChange={(e) => updateFilterorderNumber(e.target.value)} /> */}
            </div>

            <div className='col-lg-4'>
              <label className='fs-6 form-label fw-bold text-dark'>Customer</label>

              {/* <input type="text" className="form-control" placeholder="Enter Customer Name" autoComplete='off' name="firstName"
                                value={filterName} onChange={(e) => updateFilterName(e.target.value)} /> */}
            </div>
            <div className='col-lg-4'>
              <label className='fs-6 form-label fw-bold text-dark'>Invoice Date</label>
              <div className='row g-3'>
                <div className='col'>
                  {/* <Flatpickr
                                        //  value={dateState.date1}
                                        options={{ dateFormat: 'Y-m-d' }}
                                        onChange={(selectedDates) => handleDateChange(selectedDates, 'from')}
                                        className="form-control form-control-solid"
                                        placeholder="From"

                                    // value={fromDate as Date}
                                    // onChange={(e) => {
                                    //     setFromDate((e[0]))
                                    // }}                                               
                                    //name="orderDate"
                                    /> */}
                </div>
                <div className='col'>
                  {/* <Flatpickr
                                        // value={dateState.date2}
                                        options={{ dateFormat: 'Y-m-d' }}
                                        onChange={(selectedDates) => handleDateChange(selectedDates, 'to')}
                                        className="form-control form-control-solid"
                                        placeholder="To"

                                    // value={toDate as Date}
                                    // onChange={(e) => {
                                    //     setToDate((e[0]))
                                    // }}                                               
                                    // name="orderDate"
                                    /> */}
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4">
                                        <label className="fs-6 form-label fw-bold text-dark">
                                            Status
                                        </label>
                                        <Select
                                            className='form-select-solid fw-bolder'
                                            name="statusId"
                                            options={purchaseStatusOptions}
                                            placeholder="Select Status"
                                            onChange={(e) => {
                                                if (e != null) {
                                                    updateFilterStatus(e?.value === 1)
                                                }
                                                else {
                                                    updateFilterStatus(undefined)
                                                }
                                            }}
                                            isClearable={true}
                                        ></Select>
                                    </div> */}
          </div>
        </div>

        <div className={clsx('card-footer', {hide: !isExpand})}>
          <div className='d-flex align-items-center justify-content-end gap-2'>
            <button className='btn btn-primary' id='btnSearch'>
              Clear Filters
            </button>

            <button className='btn btn-primary' id='btnSearch'>
              Search
            </button>
          </div>
        </div>
      </KTCard>
      <KTCard>
        <div className='card-header'>
          <div className='card-title justify-content-between w-100'>
            <h3>Invoice List</h3>
            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Create) ? (
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => navigate('/sales/invoice/add')}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Add
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <KTCardBody className='py-4'>
          <DataTable
            value={filteredData}
            stripedRows
            lazy
            loading={isLoading}
            showGridlines
            dataKey='id'
            header={header}
            responsiveLayout='stack'
            rows={paginationModel.pageSize}
            sortMode='single'
            emptyMessage='No Invoice found.'
            tableClassName='table table-bordered table-hover mb-0'
            paginator
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
            paginatorClassName='dataTables_paginate paging_bootstrap_full_number mt-0'
            paginatorLeft={leftContent}
            onSort={onPageOrSortChange}
            onPage={onPageOrSortChange}
            first={paginationModel.first}
            totalRecords={invoiceResponse?.pagination?.totalCount}
            rowsPerPageOptions={pageSizeOption}
            sortField={paginationModel.sortField}
            sortOrder={paginationModel.sortOrder}
          >
            <Column field='invoiceNumber' header='Invoice No.' sortable sortField='invoiceNumber' />
            <Column
              field='customerName'
              header='Customer'
              sortable
              sortField='customer.printName'
            />
            <Column
              field='invoiceDate'
              header='Order Date'
              sortable
              sortField='invoiceDate'
              body={(rowData: InvoiceDto) => formatDate(rowData.invoiceDate, 'dd MMM yyyy')}
            />
            <Column
              field='orderTotal'
              header='Total Amount'
              sortable
              sortField='orderTotal'
              body={(rowData: InvoiceDto) => formatCurrency(rowData.orderTotal)}
            />
            {/* <Column field="totalAmountReceived" header="Amount Received" sortable sortField="totalAmountReceived" body={(rowData: InvoiceDto) => formatCurrency(rowData.)} /> */}
            <Column
              field='invoiceStatusName'
              header='Invoice Status'
              sortable
              sortField='invoiceStatusId'
            />
            {/* <Column
              field='paymentStatusName'
              header='Payment Status'
              sortable
              sortField='paymentStatusId'
            /> */}
            <Column
              field=''
              header='Actions'
              body={(rowData: InvoiceDto) => (
                <InvoiceAction
                  id={rowData.id}
                  rowData={rowData}
                  showCancelModal={(soid: number) => {
                    setSelectedSaleOrderId(soid)
                    setAction('Cancel')
                    setShowCancelModal(true)
                  }}
                />
              )}
            />
          </DataTable>
          {isLoading && <Loading />}
        </KTCardBody>
      </KTCard>

      <Modal show={action === 'Cancel'} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='col-xl-12 col-lg-12 order-xl-1 col-md-12 mb-3'>
            <textarea
              rows={4}
              placeholder='Reason for cancellation'
              name='notes'
              className='form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1'
              autoComplete='off'
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-light' onClick={handleClose}>
            Close
          </button>
          {cancelInvoiceLoading && <Loading />}
          <button className='btn btn-primary' onClick={() => handleConfirm()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {InvoiceList}
