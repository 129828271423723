import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from '../../../../../_metronic/helpers/crud-helper/models'
import { Expense, ExpenseQueryResponse, NewExpense } from './_models'
import { Result } from '../../../../core/models/Result'

const API_URL = process.env.REACT_APP_STORE_API_URL
const EXPENSE_BY_ID_URL = `${API_URL}/expense`
const EXPENSE_LIST_URL = `${API_URL}/expense/search`
const EXPENSE_ADD_URL = `${API_URL}/expense`

const getExpenseList = async (filter: PaginationFilter): Promise<ExpenseQueryResponse> => {
  return await axios
    .post(`${EXPENSE_LIST_URL}`, filter)
    .then((d: AxiosResponse<ExpenseQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getExpenseById = async (id: ID): Promise<Expense> => {
  return await axios
    .get(`${EXPENSE_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Expense>) => response.data)
    .then((response: Expense) => response)
    .catch((err) => {
      return err
    })
}

const createExpense = async (expense: NewExpense): Promise<Result> => {
  return await axios
    .post(EXPENSE_ADD_URL, expense)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateExpense = async (expense: Expense): Promise<Result> => {
  return await axios
    .put(`${EXPENSE_BY_ID_URL}/${expense.id}`, expense)
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
export {
  getExpenseList, getExpenseById, createExpense, updateExpense
}
