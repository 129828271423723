import React, { useEffect, useState } from 'react'
import { StatusOptions } from '../../../../../../core/models/StatusOptions';
import Select from 'react-select'
import { SelectOptionProps } from '../../../../../../core/models/SelectOptionProps';
import { Filter, PaginationFilter, initialQueryState } from '../../../../../../../_metronic/helpers';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { getCategoryList } from '../../../../category/category-list/core/_requests';
import { Category } from '../../../../category/category-list/core/_models';
import { ProductGroupListToolbar } from './ProductGroupListToolbar';
import { getDesignNumberList } from '../../../../../../core/shared/core/_request';
import { DesignNumber } from '../../../../../../core/shared/core/_model';


const ProductGroupAdvancedSearch = () => {
    const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
    const [categoryList, setCategoryList] = useState<SelectOptionProps[]>([]);
    const [designNumberList, setDesignNumberList] = useState<SelectOptionProps[]>([]);
    const { state, updateState } = useQueryRequest()
    const [filterStatus, updateFilterStatus] = useState<boolean | undefined>()
    const [filterCategoryId, updateFilterCategoryId] = useState<number[] | undefined>(undefined)
    const [filterDesignNumberId, updateFilterDesignNumberId] = useState<number[] | undefined>(undefined)
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)

    const paginationFilter: PaginationFilter = {
        pageNumber: 1,
        pageSize: 500,
        advancedFilter: {
            field: 'isActive',
            operator: 'eq',
            value: true

        }
    };



    useEffect(() => {
        let result: any[] = []
        getCategoryList(paginationFilter).then((v) => {
            result = v.data as Category[]
            const CategoryArray: any[] = []
            result.map((item: any) => {
                return CategoryArray.push({ value: item.id, label: item.parentChildCategoryName })
            })
            setCategoryList(CategoryArray)
        })

        getDesignNumberList(paginationFilter).then((v) => {
            result = v.data as DesignNumber[]
            let designArray: any[] = []
            result.map((item: any) => {
                return designArray.push({ value: item.id, label: item.name })
            })
            setDesignNumberList(designArray)
        })
    }, [])

    const updateSearchFilters = () => {

        let filters: Filter[] = []
        if (filterStatus != undefined) {
            filters.push({
                field: 'isActive',
                operator: 'eq',
                value: filterStatus
            })
        }

        if (filterCategoryId != undefined) {
            let categoryFilter: Filter[] = []

            filterCategoryId.map((id) => {
                categoryFilter.push({
                    field: 'categoryId',
                    operator: 'eq',
                    value: id
                })
            })

            if (categoryFilter.length > 1) {
                filters.push({
                    filters: categoryFilter,
                    logic: 'or'
                })
            }
            else if (categoryFilter.length == 1) {
                filters.push(categoryFilter[0])
            }
        }

        if (filterDesignNumberId != undefined) {
            let designNumberFilter: Filter[] = []

            filterDesignNumberId.map((id) => {
                designNumberFilter.push({
                    field: 'designNumberId',
                    operator: 'eq',
                    value: id
                })
            })

            if (designNumberFilter.length > 1) {
                filters.push({
                    filters: designNumberFilter,
                    logic: 'or'
                })
            }
            else if (designNumberFilter.length == 1) {
                filters.push(designNumberFilter[0])
            }
        }

        if (filters.length > 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: {
                    filters: filters,
                    logic: 'and'
                }, ...initialQueryState
            })
        }

        else if (filters.length === 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: filters[0],
                ...initialQueryState
            })
        }
        else {
            updateState({
                keyword: searchTerm,
                advancedFilter: undefined,
                ...initialQueryState
            })
        }
    }

    return (
        <>

            <div className="card mb-5">
                <div className='card-header'>
                    <div className='card-title justify-content-between w-100'>
                        <h3>Product Group Filter</h3>
                        <div className='d-flex g-5 align-items-center'>

                            <button className="btn"
                                onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
                                {
                                    advancedSearchVisible ?
                                        <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
                                        :
                                        <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
                                }
                            </button>
                        </div>

                    </div>
                </div>

                {/* <div className="row">
                        <div className="col-lg-6">
                            <div className="input-group">
                                <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    id="txtsearchText"
                                    placeholder="Search Product Group"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                               
                            </div>
                        </div>
                        <div className="col-lg-3">
                            
                        </div>
                        <div className='col-lg-3 '>
                            
                        </div>
                    </div> */}

                {advancedSearchVisible && (
                    <>
                        <div className="card-body">
                            <div className="collapse" id="kt_advanced_search_form">
                                {/* Separator */}
                                <div className="separator separator-dashed mt-9 mb-6"></div>
                                {/* Row */}
                                {/* Add your advanced search form JSX here */}
                            </div>


                            <div className="row g-4 mb-5">

                                <div className="col-md-6 col-lg-6 col-xl-4">
                                    <label className="fs-6 form-label fw-bold text-dark">
                                        Status
                                    </label>
                                    <Select
                                        className='form-select-solid'
                                        name="statusId"
                                        options={StatusOptions}
                                        placeholder="Select Status"
                                        onChange={(e) => {
                                            if (e != null) {
                                                updateFilterStatus(e?.value === 1)
                                            }
                                            else {
                                                updateFilterStatus(undefined)
                                            }
                                        }}
                                        isClearable={true}
                                    ></Select>
                                </div>

                                <div className="col-md-6 col-lg-6 col-xl-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Category
                                    </label>
                                    <Select
                                        className='form-select-solid ms-1'
                                        name="categoryId"
                                        options={categoryList}
                                        placeholder="Select category"
                                        onChange={(e) => updateFilterCategoryId((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                        isClearable={true}
                                        isMulti={true}
                                    ></Select>
                                </div>

                                <div className="col-md-6 col-lg-6 col-xl-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Design Number
                                    </label>
                                    <Select
                                        className='form-select-solid ms-1'
                                        name="designNumberId"
                                        options={designNumberList}
                                        placeholder="Select designNumber"
                                        onChange={(e) => updateFilterDesignNumberId((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                        isClearable={true}
                                        isMulti={true}
                                    ></Select>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex gap-3 justify-content-end'>
                            <button className='btn btn-primary' id='btnSearch'>
                                Clear Filters
                            </button>
                            <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                Search
                            </button>
                        </div>
                    </>
                )}

            </div>



        </>
    );

}

export default ProductGroupAdvancedSearch