function camelize(value: string) {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

function convertString(value) {
  // Split the string at each uppercase letter
  let result = value.replace(/([a-z])([A-Z])/g, '$1 $2')
  // Capitalize the first letter of each word
  result = result
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
  return result
}

export {camelize, convertString}
