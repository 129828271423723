import { SEOListSearchComponent } from "./SEOListSearchComponent"
import { SEOListToolbar } from "./SEOListToolbar"

const SEOListHeader = () => {

  return (
    <div className='card-header'>
      {/* <SEOListSearchComponent /> */}
      <div className="card-title">
        <h3>SEO List</h3>
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <SEOListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default SEOListHeader
