import { Column } from 'primereact/column'
import { DataTable, DataTableStateEvent } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { Filter, KTCard, KTCardBody, PaginationFilter } from '../../../../_metronic/helpers'
import { format } from 'date-fns'
import { getLowProductStock } from './core/_request'
import { initialLowProductStockFilters, LowProductStock, LowProductStockFilters } from './core/_model'
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { useImmer } from 'use-immer'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown'
import { toZonedTime } from "date-fns-tz";
import { getColorList, getPolishingTypeList } from '../../../core/shared/core/_request'
const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}
const initialPaginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderBy: ['product.Name asc']
}

function Product() {
  const [product, setProduct] = useState<LowProductStock[]>([])
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [paginationFilters, setPaginationFilters] = useImmer<PaginationFilter>(initialPaginationFilter);
  const [filters, setFilters] = useImmer<LowProductStockFilters>(initialLowProductStockFilters)
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<LowProductStock[]>(product);
  const [filterDateFrom, setFilterDateFrom] = useState<Date | string | undefined>(undefined);
  const [filterDateTo, setFilterDateTo] = useState<Date | string | undefined>(undefined);
  const navigate = useNavigate()

  useEffect(() => {
    getLowProductStock(paginationFilters).then((data) => {
      let result = data.data as LowProductStock[]

      setProduct(result)
    })
  }, [paginationFilters])

  const formatDate = (rowData: LowProductStock) => {
    const date = new Date(rowData.lastUpdatedOn as Date);
    return format(date, 'dd MMM yyyy');
  };

  const onPageOrSortChange = (event: DataTableStateEvent) => {
    setPaginationFilters(draft => {
      draft.pageNumber = event.page === undefined ? 1 : event.page as number + 1;
      draft.pageSize = event.rows;

      // draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`];
    });
  };
  function useFetchAndMapData(getDataFunction: any, mapFunction: any) {
    const [dataList, setDataList] = useState([]);
    useEffect(() => {
      const updatedPaginationFilter: PaginationFilter = {
        ...paginationFilter,
        advancedFilter: {
          field: 'isActive',
          operator: 'eq',
          value: true,
        },

      };
      getDataFunction(updatedPaginationFilter).then((response: { data: any; }) => {
        const result = response.data;
        const mappedData = result.map((item: any) => mapFunction(item));
        setDataList(mappedData);
      });
    }, []);

    return dataList;
  }
  const colorList = useFetchAndMapData(getColorList, (item: any) => ({
    value: item.id,
    label: item.name,
  }));

  const polishingTypeList = useFetchAndMapData(getPolishingTypeList, (item: any) => ({
    value: item.id,
    label: item.name
  }));

  const handleSearch = () => {
    const lstfilters: Filter[] = []
    if (filters.productName != undefined && filters.productName != '') {
      lstfilters.push({
        field: 'product.name',
        operator: 'contains',
        value: filters.productName
      })
    }


    if (filters.colorId != undefined && filters.colorId != -1) {
      lstfilters.push({
        field: 'product.colorId',
        operator: 'eq',
        value: filters.colorId
      })
    }

    if (filters.polishingTypeId != undefined && filters.polishingTypeId != -1) {
      lstfilters.push({
        field: 'product.polishingTypeId',
        operator: 'eq',
        value: filters.polishingTypeId
      })
    }


    if (filterDateFrom) {
      const startDate = toZonedTime(new Date(filterDateFrom as Date), 'Asia/Kolkata');
      lstfilters.push({
        field: 'lastUpdatedOn',
        operator: 'gte',
        value: format(startDate, 'yyyy-MM-dd 00:00:00')
      });
    }

    if (filterDateTo) {
      const endDate = toZonedTime(new Date(filterDateTo as Date), 'Asia/Kolkata');
      lstfilters.push({
        field: 'lastUpdatedOn',
        operator: 'lte',
        value: format(endDate, 'yyyy-MM-dd 23:59:59')
      });
    }

    if (lstfilters.length > 1) {
      setPaginationFilters((draft) => {
        draft.keyword = globalFilterValue;
        draft.advancedFilter = {
          filters: lstfilters,
          logic: 'and'
        }
      });
    }

    else if (lstfilters.length === 1) {
      setPaginationFilters((draft) => {
        draft.keyword = globalFilterValue;
        draft.advancedFilter = lstfilters[0]
      });
    }
    else {
      setPaginationFilters((draft) => {
        draft.keyword = globalFilterValue;
        draft.advancedFilter = undefined
      });
    }

    setGlobalFilterValue('');
  }

  useEffect(() => {
    const newFilteredData = product.filter((item: LowProductStock) =>
      Object.values(item).some((value: string | number | Date | null) =>
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    );
    setFilteredData(newFilteredData);
  }, [product, globalFilterValue]);

  const handleDateChange = (selectedDates: Date[], dateName: string) => {
    if (dateName === 'startDate') {
      if (selectedDates[0] === undefined) {
        setFilterDateFrom('')
      }
      else {

        setFilterDateFrom(selectedDates[0]?.toDateString());
      }

    } else if (dateName === 'endDate') {
      if (selectedDates[0] === undefined) {
        setFilterDateTo('')
      }
      else {

        setFilterDateTo(selectedDates[0]?.toDateString());
      }
    }

  };
  return (
    <>
      <div className="card mb-5">
        <div className="card-header justify-content-between align-items-center">
          <div className="card-title">
            <h3>Low Stock Product Filter</h3>
          </div>
          <button className="btn"
            onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
            {
              advancedSearchVisible ?
                <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
                :
                <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
            }
          </button>
        </div>
        {advancedSearchVisible && (
          <>
            <div className="card-body">
              {/* <div className="row mb-7">
            <div className="col-lg-6">
              <div className="input-group">
                <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>

                <input
                  type="text"
                  className="form-control form-control-solid ps-10"
                  id="txtsearchText"
                  placeholder="Search Products"
                  value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)}
                />
                <button className="btn btn-primary" id="btnSearch"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <button
                className="btn btn-link"
                onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
              >
                Advanced Search
              </button>
            </div>
            <div className='col-lg-2 '>
              <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}>Back

              </button>
            </div>

          </div> */}

              <div className="collapse" id="kt_advanced_search_form">
                <div className="separator separator-dashed mt-9 mb-6"></div>
              </div>

              <div className="row">
                <div className="col-xl04 col-lg-4 col-md-4 mb-5">
                  <div
                    className='form-group'>
                    <label className="fs-6 form-label fw-bold text-dark">Product Name</label>
                    <input type="text" className="form-control form-control-solid" placeholder="Product Name" autoComplete='off' name="product.name"
                      value={filters.productName} onChange={(e) => setFilters(draft => { draft.productName = e.target.value })} />
                  </div>
                </div>
                <div className="col-xl04 col-lg-4 col-md-4 mb-5">
                  <div className='form-group'>
                    <label className="fs-6 form-label fw-bold text-dark">Color</label>
                    <Dropdown
                      options={colorList}
                      name='cboColor'
                      optionLabel="label"
                      placeholder="Select Color"
                      className="form-control form-control-solid select-dropdown"
                      showClear
                      value={filters.colorId}
                      onChange={(e) => {
                        if (e != undefined && e != null)
                          setFilters(draft => { draft.colorId = e.value })
                        else
                          setFilters(draft => { draft.colorId = -1 })
                      }}
                    />
                  </div>
                </div>

                <div className="col-xl04 col-lg-4 col-md-4 mb-5">
                  <div className='form-group'>
                    <label className="fs-6 form-label fw-bold text-dark">Polishing Type</label>
                    <Dropdown
                      options={polishingTypeList}
                      name='cboPolishingType'
                      optionLabel="label"
                      placeholder="Select PolishingType"
                      className="form-control form-control-solid select-dropdown"
                      showClear
                      value={filters.polishingTypeId}
                      onChange={(e) => {
                        if (e != undefined && e != null)
                          setFilters(draft => { draft.polishingTypeId = e.value })
                        else
                          setFilters(draft => { draft.polishingTypeId = -1 })
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl04 col-lg-4 col-md-4 mb-5">
                  <label className="fs-6 form-label fw-bold text-dark">
                    Date
                  </label>
                  <div className="row g-3">
                    <div className="col">
                      <Flatpickr
                        //  value={dateState.date1}
                        options={{ dateFormat: 'Y-m-d' }}
                        onChange={(selectedDates) => handleDateChange(selectedDates, 'startDate')}
                        className="form-control form-control-solid"
                        placeholder="From" />
                    </div>
                    <div className="col">
                      <Flatpickr
                        // value={dateState.date2}
                        options={{ dateFormat: 'Y-m-d' }}
                        onChange={(selectedDates) => handleDateChange(selectedDates, 'endDate')}
                        className="form-control form-control-solid"
                        placeholder="To" />
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div className='card-footer d-flex gap-3 justify-content-end'>
              <button className='btn btn-primary' id='btnSearch'>
                Clear Filters
              </button>
              <button className="btn btn-primary" id="btnSearch"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </>
        )}
      </div>


      <KTCard>
        <div className='card-header justify-content-between align-items-center'>
          <div className='card-title'>
            <h3>Low Stock Product</h3>
          </div>
        </div>
        <KTCardBody>
          <>
            <div>
              <DataTable
                className='table-responsive'
                tableClassName='table border table-striped table-row-bordered gy-5 gs-7'
                paginatorClassName='pagination'
                paginatorLeft
                value={filteredData}
                showGridlines
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'
                rows={paginationFilters.pageSize}
                paginator
                rowsPerPageOptions={[10, 25, 50]}
                onPage={onPageOrSortChange}
              >
                <Column
                  //className='text-start text-muted fw-bolder fs-6  gs-0 min-w-125px'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='productName'
                  header='Product '
                  sortable
                  style={{ minWidth: '6rem' }} />
                <Column
                  //className='text-start text-muted fw-bolder fs-6  gs-0 min-w-125px'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='availableQuantity'
                  header='Quantity'
                  sortable />

                <Column
                  //className='text-start text-muted fw-bolder fs-6  gs-0 min-w-125px'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='lowStockAlert'
                  header='Low Stock Alert'
                  sortable />

                <Column
                  //className='text-start text-muted fw-bolder fs-6   gs-0 min-w-125px'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='lastUpdatedOn'
                  header='Last Updated On'
                  sortable
                  body={(rowData) => formatDate(rowData)} />

                <Column
                  //className='text-start text-muted fw-bolder fs-6  gs-0 min-w-125px'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='colorName'
                  header='Color'
                  sortable
                  style={{ minWidth: '6rem' }} />
                <Column
                  // className='text-start text-muted fw-bolder fs-6  gs-0 min-w-125px'
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='polishingTypeName'
                  header='Polishing Type'
                  sortable />


              </DataTable>
            </div>
          </>
        </KTCardBody>
        <div className="card-footer text-end">
        <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}><i className='ki-duotone ki-left fs-2' /> Back </button>
        </div>
      </KTCard></>
  )
}

export default Product
