import { useState } from 'react'
import { KTCard, KTCardBody, KTIcon } from '../../../../../_metronic/helpers'

import { getBankAccountList } from '../core/_requests'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useImmer } from 'use-immer'
import { useQuery } from 'react-query'
import { BankListActionCell } from '../components/BankListActionCell'
import { useNavigate } from 'react-router-dom'

const BankAccountList = () => {
  const navigate = useNavigate()
  const [filterQuery] = useState({})
  const [paginationFilters, setPaginationFilters] = useImmer({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [] as string[],
    order: '' as 'asc' | 'desc',
    first: 0,
    sortField: '',
  })

  const { data: response, isLoading } = useQuery(
    ['getBankAccountRecords', paginationFilters],
    async () => {
      return await getBankAccountList({
        ...filterQuery,
        ...paginationFilters,
        order: paginationFilters.order as 'asc' | 'desc',
      })
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  const onSort = (event: any) => {
    setPaginationFilters((draft) => {
      draft.pageNumber = event.page === undefined ? 1 : (event.page as number) + 1
      draft.pageSize = event.rows
      draft.first = event.first
      draft.sortField = event.sortField
      event.sortField && (draft.order = event.sortOrder === 1 ? 'asc' : 'desc')
      event.sortField &&
        (draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`])
    })
  }

  return (
    <>
      <KTCard>
        <div className='card-header justify-content-bewteen align-items-center'>
          <div className='card-title'>
            <h3>Bank Account</h3>
          </div>
          <div className='d-flex justify-content-end mt-4'>
            <div className='m-0'></div>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => navigate('/settings/bank-account/add')}
            >
              <KTIcon iconName='plus' className='fs-2' />
              Add
            </button>
          </div>
        </div>
        <KTCardBody className='py-4'>
          <DataTable
            value={response?.data}
            tableClassName='border'
            stripedRows
            tableStyle={{ minWidth: '50rem' }}
            sortOrder={paginationFilters.order === 'asc' ? 1 : -1}
            sortField={paginationFilters.orderBy[0]?.split(' ')[0]}
            loading={isLoading}
            paginator
            rows={response?.pagination?.pageSize}
            totalRecords={response?.pagination?.totalCount}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            onSort={onSort}
            onPage={onSort}
            first={0}
          >
            <Column field='name' header='Bank Name' sortable sortField='name' />
            <Column
              field='accountHolderName'
              header='Account Name'
              sortable
              sortField='accountHolderName'
            />
            <Column
              field='accountTypeName'
              header='Account Type'
              sortable
              sortField='accountTypeName'
            />
            <Column
              field='isDefault'
              header='Default Account'
              sortable
              sortField='isDefault'
              body={(rowData) => (rowData.isDefault ? 'Yes' : 'No')}
            />

            <Column
              field='actions'
              header='Actions'
              body={(rowData) => <BankListActionCell id={rowData.id} />}
            />
          </DataTable>
        </KTCardBody>
      </KTCard>
    </>
  )
}
export default BankAccountList
