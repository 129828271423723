import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getExpenseById, updateExpense } from '../core/_requests'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps'
import { getBankList, getPaymentModeList, getVendorList } from '../../paymentMade/_request'
import { getStaffList } from '../../../settings/staff/staff-list/core/_requests'
import { getExpenseCategoryList } from '../../expenseCategory/core/_requests'
import Flatpickr from 'react-flatpickr'
import { PaginationFilter } from '../../../../../_metronic/helpers/crud-helper/models'
import clsx from 'clsx'
import { PaymentMode } from '../../paymentMade/_models'
import CustomFlatPicker from '../../../../core/shared/components/CustomFlatPicker'
import { Loading } from '../../../../core/shared/components/Loading'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import { toZonedTime } from 'date-fns-tz'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { camelize } from '../../../../core/utils/StringHelpers'
import { Result } from '../../../../core/models/Result'
import CalendarInput from '../../../../core/shared/components/CalendarInput'

const ExpensesEditPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [vendorList, setVendorList] = useState<SelectOptionProps[]>([])
  const [staffList, setStaffList] = useState<SelectOptionProps[]>([])
  const [expenseList, setExpenseList] = useState<SelectOptionProps[]>([])
  const [banks, setBanks] = useState<SelectOptionProps[]>([])
  const [initialValues, setInitialValues] = useState<any>({})
  const { data: expenseDetails, isLoading } = useQuery({
    queryKey: ['expenseDetails'],
    queryFn: () => getExpenseById(id),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
  })

  const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,
  }

  const expenseSchema = Yup.object().shape({
    vendorId: Yup.number(),
    expenseCategoryId: Yup.number().required('Please enter Expense Category Name.'),
    expenseDate: Yup.date().required('Please enter Expense Date.'),
    paymentModeId: Yup.number().required('Please Select Payment Mode'),
    staffId: Yup.number(),
    expenseAmount: Yup.number()
      .required('Please enter Expense Amount.')
      .min(0, 'Expense Amount cannot be negative.'),
    chequeBankName: Yup.string().when('paymentModeId', ([paymentModeId], schema) => {
      return paymentModeId === 2
        ? schema.required('Please Enter Cheque Bank Name')
        : schema.notRequired()
    }),
    chequeNumber: Yup.string().when('paymentModeId', ([paymentModeId], schema) => {
      return paymentModeId === 2
        ? schema
          .required('Please Enter Cheque Number')
          .min(6, 'Cheque Number must be 6 digits')
          .max(6, 'Cheque Number must be 6 digits')
        : schema.notRequired()
    }),
    chequeDate: Yup.date().when('paymentModeId', ([paymentModeId], schema) => {
      return paymentModeId === 2
        ? schema.required('Please Enter Cheque Date')
        : schema.notRequired()
    }),

    bankId: Yup.number().when('paymentModeId', ([paymentModeId], schema) => {
      return paymentModeId !== 1 ? schema.required('Please Select Bank') : schema.notRequired()
    }),
  })

  useEffect(() => {
    setInitialValues({
      id: expenseDetails?.id,
      staffId: expenseDetails?.staffId,
      vendorId: expenseDetails?.vendorId,
      expenseCategoryId: expenseDetails?.expenseCategoryId,
      expenseCategory: expenseDetails?.expenseCategory,
      expenseDate: expenseDetails?.expenseDate,
      expenseAmount: expenseDetails?.expenseAmount,
      notes: expenseDetails?.notes,
      expenseNumber: expenseDetails?.expenseNumber,
      paymentModeId: expenseDetails?.paymentModeId,
      chequeBankName: expenseDetails?.chequeBankName,
      chequeNumber: expenseDetails?.chequeNumber,
      chequeDate: expenseDetails?.chequeDate,
      bankId: expenseDetails?.bankId,
    })
  }, [!isLoading, expenseDetails])

  const handleApiError = (
    result: Result,
    setFieldError: (field: string, message: string) => void
  ) => {
    if (result.statusCode === 400) {
      result.propertyResults.forEach((error) =>
        setFieldError(camelize(error.propertyName), error.errorMessage)
      )
    }
    toast.error('An error occurred while saving the purchase order.')
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: expenseSchema,
    onSubmit: async (expense, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        const exdate = toZonedTime(new Date(expense?.expenseDate as Date), 'Asia/Kolkata')
        const chequeDate = toZonedTime(expense.chequeDate as Date, 'Asia/Kolkata')

        const expenseDateString = format(exdate, 'yyyy-MM-dd')
        const chequeDateString = format(chequeDate, 'yyyy-MM-dd')
        const payload = {
          id: Number(expense?.id),
          vendorId: Number(expense.vendorId),
          staffId: Number(expense.staffId),
          expenseCategoryId: Number(expense.expenseCategoryId),
          expenseAmount: expense.expenseAmount,
          expenseDate: expenseDateString,
          notes: expense.notes,
          paymentModeId: expense.paymentModeId,
          bankId: Number(expense.bankId),
          chequeBankName: expense?.paymentModeId == 2 ? expense.chequeBankName : undefined,
          chequeNumber: expense?.paymentModeId == 2 ? expense.chequeNumber : undefined,
          chequeDate: expense?.paymentModeId == 2 ? chequeDateString : undefined,
        }
        const result = await updateExpense(payload)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          toast.success('Expense created successfully!')
          navigate('/purchase/expense/list')
        } else {
          handleApiError(result, setFieldError)
        }
      } catch (ex) {
        toast.error('Error Occurred .')
        console.error(ex)
      }
    },
  })

  const { data: vendorResponse, isLoading: vendorLoading } = useQuery(
    ['vendor'],
    () => {
      return getVendorList(paginationFilter)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )
  const { data: bankList, isLoading: isBankListLoading } = useQuery({
    queryKey: ['bankList'],
    queryFn: () => getBankList({}),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
  })

  const { data: staffResponse, isLoading: staffLoading } = useQuery(
    ['staff'],
    () => {
      return getStaffList(paginationFilter)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )
  const { data: expenseResponse, isLoading: expenseLoading } = useQuery(
    ['expense'],
    () => {
      return getExpenseCategoryList(paginationFilter)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )
  useEffect(() => {
    let vendorArray: any[] = []

    vendorResponse?.data?.map((item: any) => {
      vendorArray.push({ value: item.id, label: item.printName })
    })

    setVendorList(vendorArray)
    let staffArray: any[] = []

    staffResponse?.data?.map((item: any) => {
      staffArray.push({ value: item.id, label: item.firstName })
    })
    setStaffList(staffArray)

    let expenseArray: any[] = []
    expenseResponse?.data?.map((item: any) => {
      expenseArray.push({ value: item.id, label: item.name })
    })
    setExpenseList(expenseArray)

    if (bankList?.data) {
      let bankArray: any[] = []
      bankList?.data.map((item: any) => {
        return bankArray.push({ value: item.id, label: item.name })
      })
      setBanks(bankArray)
    }
  }, [staffLoading, vendorLoading, expenseLoading, isBankListLoading])

  const { data: paymentModeList } = useQuery({
    queryKey: ['paymentModeList'],
    queryFn: () => getPaymentModeList(),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
  })

  return (
    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_expense_form'
        className='form'
        onSubmit={formik.handleSubmit}
      // noValidate
      >
        <div
          className='d-flex flex-column flex-xl-row gap-5 gap-lg-5 mb-10'
          id='kt_modal_add_expense_scroll'
          data-kt-scroll='true'
        >
          <div className='card flex-row-fluid'>
            <div className='card-header'>
              <div className='card-title w-100'>
                <div className='row mt-3 w-100 justify-content-between'>
                  <label className='col-6 col-form-label fw-bold fs-4'>Expense Details</label>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='row d-flex flex-row align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='required fw-bold fs-6 mb-2'>Expense Category</label>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 mb-5'>
                    <Field
                      className={clsx(
                        'form-select-solid',
                        {
                          'is-invalid':
                            formik.touched.expenseCategoryId && formik.errors.expenseCategoryId,
                        },
                        {
                          'is-valid':
                            formik.touched.expenseCategoryId && !formik.errors.expenseCategoryId,
                        }
                      )}
                      {...formik.getFieldProps('expenseCategoryId')}
                      name='expenseCategoryId'
                      options={expenseList}
                      component={CustomSelect}
                      placeholder='Select Expense Category'
                      isMulti={false}
                    ></Field>
                    {formik.touched.expenseCategoryId && formik.errors.expenseCategoryId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.expenseCategoryId as string}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='row d-flex flex-row align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='required fw-bold fs-6 mb-2'>Payment Mode</label>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 mb-5'>
                    <div className='d-flex flex-row flex-wrap gap-2'>
                      {paymentModeList?.data?.map((option: PaymentMode) => (
                        <div key={option.id} className='form-check form-check-inline'>
                          <input
                            type='radio'
                            className='form-check-input'
                            name='paymentModeId'
                            id={`paymentMode-${option.id}`}
                            checked={formik?.values?.paymentModeId === option.id}
                            onChange={() => formik.setFieldValue('paymentModeId', option.id)}
                          />
                          <label className='form-check-label' htmlFor={`paymentMode-${option.id}`}>
                            {option.name}
                          </label>
                        </div>
                      ))}
                      {formik.touched.paymentModeId && formik.errors.paymentModeId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.paymentModeId as string}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {formik.values.paymentModeId == 2 && (
                <>
                  <div className='row'>
                    <div className='row d-flex flex-row align-items-center'>
                      <div className='col-xl-2 col-lg-3 col-md-4'>
                        <label className='required fw-bold fs-6 mb-2'>Cheque Bank Name</label>
                      </div>
                      <div className='col-xl-6 col-lg-9 col-md-8'>
                        <Field
                          className='form-control'
                          type='text'
                          {...formik.getFieldProps('chequeBankName')}
                          value={formik.values.chequeBankName}
                          name={'chequeBankName'}
                          placeholder={'Enter Cheque Bank Name'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const formattedValue = e.target.value.replace(/[^a-zA-Z]/g, ' ')
                            formik.setFieldValue('chequeBankName', formattedValue)
                          }}
                        ></Field>
                        {formik.touched.chequeBankName && formik.errors.chequeBankName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.chequeBankName as string}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row my-5'>
                    <div className='col-md-6'>
                      <div className='row d-flex flex-row align-items-center'>
                        <div className='col-4'>
                          <label className='required fw-bold fs-6 mb-2'>Cheque Number</label>
                        </div>
                        <div className='col-8'>
                          <Field
                            className='form-control'
                            type='text'
                            {...formik.getFieldProps('chequeNumber')}
                            value={formik.values.chequeNumber}
                            name={'chequeNumber'}
                            placeholder={'Enter Cheque Number'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const numericValue = e.target.value.replace(/\D/g, '')
                              formik.setFieldValue('chequeNumber', numericValue)
                            }}
                          />
                          {formik.touched.chequeNumber && formik.errors.chequeNumber && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.chequeNumber as string}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='row align-items-center'>
                        <div className='col-4'>
                          <label className='required fw-bold fs-6 mb-2'>Cheque Date</label>
                        </div>
                        <div className='col-8'>
                          <CalendarInput
                            name='chequeDate'
                            value={
                              formik.values.chequeDate &&
                                !isNaN(Date.parse(formik.values.chequeDate as unknown as string))
                                ? new Date(formik.values.chequeDate)
                                : null
                            }
                            setFieldValue={formik.setFieldValue}
                            placeholder='Cheque Date'
                            className='form-control'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {formik.values.paymentModeId !== 1 && (
                <div className='row'>
                  <div className='row d-flex flex-row align-items-center'>
                    <div className='col-xl-2 col-lg-3 col-md-4'>
                      <label className='required fw-bold fs-6 mb-2'>Expense Category</label>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 mb-5'>
                      <Field
                        className='form-select-solid'
                        options={banks || []}
                        component={CustomSelect}
                        {...formik.getFieldProps('bankId')}
                        value={formik.values.bankId}
                        name={'bankId'}
                        placeholder={'Select Bank'}
                      ></Field>
                      {formik.touched.bankId && formik.errors.bankId && (
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.bankId as string}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className='row'>
                <div className='row d-flex flex-row align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='required fw-bold fs-6 mb-2'>Expense Date</label>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 mb-5'>
                    <div className='w-100 p-calendar-wraper'>
                      <CalendarInput
                        name='expenseDate'
                        value={
                          formik.values.expenseDate &&
                            !isNaN(Date.parse(formik.values.expenseDate as unknown as string))
                            ? new Date(formik.values.expenseDate)
                            : null
                        }
                        setFieldValue={formik.setFieldValue}
                        placeholder='Expense Date'
                        className='form-control'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='row d-flex flex-row align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='required fw-bold fs-6 mb-2'>Expense Amount</label>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 mb-5'>
                    <input
                      placeholder='Expense Amount'
                      {...formik.getFieldProps('expenseAmount')}
                      type='number'
                      name='expenseAmount'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        { 'is-invalid': formik.touched.expenseAmount && formik.errors.expenseAmount },
                        {
                          'is-valid': formik.touched.expenseAmount && !formik.errors.expenseAmount,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.expenseAmount && formik.errors.expenseAmount && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.expenseAmount as string}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='row d-flex flex-row align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='fw-bold fs-6 mb-2'>Vendor Name</label>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 mb-5'>
                    <Field
                      type='select'
                      className={clsx(
                        'form-select-solid',
                        { 'is-invalid': formik.touched.vendorId && formik.errors.vendorId },
                        {
                          'is-valid': formik.touched.vendorId && !formik.errors.vendorId,
                        }
                      )}
                      {...formik.getFieldProps('vendorId')}
                      name='vendorId'
                      options={vendorList}
                      component={CustomSelect}
                      placeholder='Select Vendor'
                      isMulti={false}
                    ></Field>
                    {formik.touched.vendorId && formik.errors.vendorId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.vendorId as string}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='row d-flex flex-row align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='fw-bold fs-6 mb-2'>Staff Name</label>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 mb-5'>
                    <Field
                      className={clsx(
                        'form-select-solid',
                        { 'is-invalid': formik.touched.staffId && formik.errors.staffId },
                        {
                          'is-valid': formik.touched.staffId && !formik.errors.staffId,
                        }
                      )}
                      {...formik.getFieldProps('staffId')}
                      name='staffId'
                      options={staffList}
                      component={CustomSelect}
                      placeholder='Select Staff'
                      isMulti={false}
                    ></Field>
                    {formik.touched.staffId && formik.errors.staffId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.staffId as string}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row'>
              <div className='row d-flex flex-row align-items-center'>
                  <div className='col-xl-2 col-lg-3 col-md-4'>
                    <label className='fw-bold fs-6 mb-2'>Notes</label>
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 mb-5'>
                  <textarea
                    rows={3}
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.notes && formik.errors.notes },
                      {
                        'is-valid': formik.touched.notes && !formik.errors.notes,
                      }
                    )}
                    disabled={formik.isSubmitting}
                    placeholder='Notes'
                    {...formik.getFieldProps('notes')}
                    name='notes'
                  />
                  {formik.touched.notes && formik.errors.notes && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.notes as string}</span>
                      </div>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer'>
              <div className='text-end'>
                <button
                  type='reset'
                  onClick={() => navigate('/purchase/expense/list')}
                  className='btn btn-light me-3'
                  data-kt-regions-modal-action='cancel'
                  disabled={formik.isSubmitting}
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-regions-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>Submit</span>
                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {formik.isSubmitting && <Loading />}
    </FormikProvider>
  )
}

export default ExpensesEditPage
